// src/routesConfig.js
import Home from "./Pages/Home";
import AboutUs from "./Pages/AboutUs";
import Services from "./Pages/Services/Services/Services";
import ProductEngineering from "./Pages/Services/Services/ProductEngineering";
import MobileAppDevelopment from "./Pages/Services/Services/MobileAppDevelopment";
import UiUxDesign from "./Pages/Services/Services/UiUxDesign";
import CustomSoftwareDevelopment from "./Pages/Services/Services/CustomSoftwareDevelopment";
import HireDevelopers from "./Pages/HireDevelopers/HireDevelopers";
import CloudServices from "./Pages/Services/Services/CloudServices";
import EcommerceDevelopment from "./Pages/Services/Services/EcommerceDevelopment";
import WebDevelopment from "./Pages/Services/Services/WebDevelopment";
import HireMobileAppDeveloper from "./Pages/HireDevelopers/HireMobileAppDeveloper";
import HireFrontendDeveloper from "./Pages/HireDevelopers/HireFrontendDeveloper";
import HireBackendDeveloper from "./Pages/HireDevelopers/HireBackendDeveloper";
import HireStackDevelopers from "./Pages/HireDevelopers/HireStackDevelopers";
import ContactUs from "./Pages/ContactUs/ContactUs";
import PrivacyPolicy from "./Pages/PrivacyPolicy/PrivacyPolicy";
import SiteMap from "./Pages/SiteMap/SiteMap";
import TermsAndConditions from "./Pages/TermsAndConditions/TermsAndConditions";
import NotFound from "./components/NotFound";
import Career from "./Pages/Career/Career";
import HRExecutiveRecruiter from "./Pages/Jobs/HRExecutiveRecruiter";
import BusinessDevelopmentExecutive from "./Pages/Jobs/BusinessDevelopmentExecutive";
import FlutterDeveloper from "./Pages/Jobs/FlutterDeveloper";
import ReactNativeDeveloper from "./Pages/Jobs/ReactNativeDeveloper";
import AndroidDeveloper from "./Pages/Jobs/AndroidDeveloper.jsx";
import IOSDeveloper from "./Pages/Jobs/IOSDeveloper.jsx";
import IonicDeveloper from "./Pages/Jobs/IonicDeveloper.jsx";
import AngularJSDeveloper from "./Pages/Jobs/AngularJSDeveloper.jsx";
import ReactJSDeveloper from "./Pages/Jobs/ReactJSDeveloper.jsx";
import VueJSDeveloper from "./Pages/Jobs/VueJSDeveloper.jsx";
import JavaDeveloper from "./Pages/Jobs/JavaDeveloper.jsx";
import PHPDeveloper from "./Pages/Jobs/PHPDeveloper.jsx";
import NodeJSDeveloper from "./Pages/Jobs/NodeJSDeveloper.jsx";
import DotNetDeveloper from "./Pages/Jobs/DotNetDeveloper.jsx";
import MeanStackDeveloper from "./Pages/Jobs/MeanStackDeveloper.jsx";
import MernStackDeveloper from "./Pages/Jobs/MernStackDeveloper.jsx";
import FullStackDeveloper from "./Pages/Jobs/FullStackDeveloper";
import PythonDeveloper from "./Pages/Jobs/PythonDeveloper.jsx";
import BusinessIntelligence from "./Pages/Services/Services/BusinessIntelligence.jsx";
import FlutterFlowDeveloper from "./Pages/Jobs/FlutterFlowDeveloper.jsx";
const routes = [
  { path: "/", element: <Home /> },
  { path: "/about-us", element: <AboutUs /> },
  { path: "/services", element: <Services /> },
  { path: "/services/product-engineering", element: <ProductEngineering /> },
  { path: "/services/mobile-app-development", element: <MobileAppDevelopment /> },
  { path: "/services/ui-ux-design", element: <UiUxDesign /> },
  { path: "/services/custom-software-development", element: <CustomSoftwareDevelopment /> },
  { path: "/services/cloud-services", element: <CloudServices /> },
  { path: "/services/ecommerce-development", element: <EcommerceDevelopment /> },
  { path: "/services/web-development", element: <WebDevelopment /> },
  { path: "/services/business-intelligence", element: <BusinessIntelligence /> },
  { path: "/services/hire-developers", element: <HireDevelopers /> },
  { path: "/hire-developers/hire-mobile-app-developer", element: <HireMobileAppDeveloper /> },
  { path: "/hire-developers/hire-frontend-developer", element: <HireFrontendDeveloper /> },
  { path: "/hire-developers/hire-backend-developer", element: <HireBackendDeveloper /> },
  { path: "/hire-developers/hire-stack-developers", element: <HireStackDevelopers /> },
  { path: "/career", element: <Career /> },
  { path: "/career/jobs/business-development-executive", element: <BusinessDevelopmentExecutive /> },
  { path: "/career/jobs/hr-executive-recruiter", element: <HRExecutiveRecruiter /> },
  { path: "/career/jobs/flutter-developer", element: <FlutterDeveloper /> },
  { path: "/career/jobs/flutterflow-developer", element: <FlutterFlowDeveloper /> },
  { path: "/career/jobs/react-native-developer", element: <ReactNativeDeveloper /> },
  { path: "/career/jobs/android-developer", element: <AndroidDeveloper /> },
  { path: "/career/jobs/ios-developer", element: <IOSDeveloper /> },
  { path: "/career/jobs/ionic-developer", element: <IonicDeveloper /> },
  { path: "/career/jobs/angularjs-developer", element: <AngularJSDeveloper /> },
  { path: "/career/jobs/reactjs-developer", element: <ReactJSDeveloper /> },
  { path: "/career/jobs/vuejs-developer", element: <VueJSDeveloper /> },
  { path: "/career/jobs/python-developer", element: <PythonDeveloper /> },
  { path: "/career/jobs/java-developer", element: <JavaDeveloper /> },
  { path: "/career/jobs/php-developer", element: <PHPDeveloper /> },
  { path: "/career/jobs/nodejs-developer", element: <NodeJSDeveloper /> },
  { path: "/career/jobs/dotnet-developer", element: <DotNetDeveloper /> },
  { path: "/career/jobs/mean-stack-developer", element: <MeanStackDeveloper /> },
  { path: "/career/jobs/mern-stack-developer", element: <MernStackDeveloper /> },
  { path: "/career/jobs/full-stack-developer", element: <FullStackDeveloper /> },
  { path: "/contact-us", element: <ContactUs /> },
  { path: "/privacy-policy", element: <PrivacyPolicy /> },
  { path: "/sitemap", element: <SiteMap /> },
  { path: "/terms-and-conditions", element: <TermsAndConditions /> },
  { path: "*", element: <NotFound /> }
];
export default routes;
