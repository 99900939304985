import React from "react";
import EnterpriseSolution from "../../../sections/Services/EnterpriseSolution";
import Breadcrumbs from "../../../components/Breadcrumbs";

export default function CustomSoftwareDevelopment() {
  return (
    <>
      <div id="content" className="site-content">
        <Breadcrumbs
          title="Custom Software Development"
          paths={[
            // { name: "Home", url: "/" },
            { name: "Services", url: "/services" },
            { name: "Custom Software Development" },
          ]}
        />
        <section className="aboutus-section">
          <div className="container ">
            <div className="row mt-5">
              <div className="col-md-10 mx-auto mt-3">
                <div className="section-title text-center wow fadeInUp">
                  <h2 className="wow fadeInUp" data-wow-delay="0.2s">End-to-End Software Development Services</h2>
                  <div className="dividebar wow fadeInUp" data-wow-delay="0.4s"></div>
                  <p className="mt-3 wow fadeInUp" data-wow-delay="0.5s">
                    From envisioning new digital opportunities to uncovering the
                    true business impact, we offer profound tech expertise,
                    agile custom software solutions, and proven experience in
                    almost all business domains. We help in shaping your
                    business ideas into excellent products to gain a strategic
                    advantage in the market.
                  </p>
                  <h4 className="mt-5 wow fadeInUp" data-wow-delay="0.8s">Our Software Development process</h4>
                  <img
                    className="mt-5 wow fadeInUp" data-wow-delay="1s"
                    src="../../wp-content/themes/wp-bootstrap-4/assets/images/custom-dev-software.png"
                    alt="chatbot services"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="customsoftware-section">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <ul className="text-white wow fadeInLeft">
                  <li>
                    <h4>Software Consulting</h4>
                    <p>
                      Our strategic software consulting helps you succeed in
                      your digital transformation that will enable you to reach
                      your business goals.
                    </p>
                  </li>
                  <li>
                    <h4>Custom Software Development</h4>
                    <p>
                      We build scalable applications which are used by consumers
                      on a global scale, every day. It becomes an easier,
                      faster, and more efficient solution to consumer needs
                    </p>
                  </li>
                  <li>
                    <h4>Enterprise Software Solutions</h4>
                    <p>
                      Our innovative enterprise software solutions support and
                      streamline your business operations to boost agility and
                      operational efficiency.
                    </p>
                  </li>
                </ul>
              </div>
              <div className="col-md-4 ml-auto">
                <ul className="alignRight text-white wow fadeInRight">
                  <li>
                    <h4>Software Integration</h4>
                    <p>
                      We integrate multiple data sources with business software
                      to attain deeper insights, better synchronization, and
                      more effective collaboration, resulting in better
                      decisions.
                    </p>
                  </li>
                  <li>
                    <h4>Quality Assurance</h4>
                    <p>
                      We put quality in the priority lane for higher success
                      rates. Our testing experts ensure quality assurance to
                      drive agility, create value and address requirements of
                      complex integrations.
                    </p>
                  </li>
                  <li>
                    <h4>Maintenance &amp; Support</h4>
                    <p>
                      Whether you need support in terms of troubleshooting
                      software anomalies or fixing bugs, we are there for you.
                      Even after your project is live and accessible!
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <EnterpriseSolution />
      </div>
    </>
  );
}
