import React from "react";
import JobDescription from "./JobDescription";
function HRExecutiveRecruiter() {
  return (
    <div className="container">
      <div className="row pt-5 pt-lg-0 pb-5 my-5">
        <div className="col-md-12 wp-bp-content-width">
          <JobDescription
            title="HR Executive Recruiter"
            skills={[
              "Recruitment", "Talent Acquisition", "Screening", "Interviewing", "Applicant Tracking Systems(ATS)", "Onboarding", "HRIS", "Employer Branding"
            ]}
            responsibilities={[
              "Manage end-to-end recruitment process for various positions.",
              "Source, screen, and evaluate qualified candidates through multiple recruitment channels.",
              "Conduct interviews (phone, video, and in-person) and coordinate with hiring managers for final decisions.",
              "Collaborate with department heads to understand staffing needs and job requirements.",
              "Use Applicant Tracking Systems (ATS) to manage candidates and maintain a talent pool for future hiring needs.",
              "Participate in job fairs, career events, and employer branding initiatives to attract top talent.",
             "Prepare and extend job offers, ensuring a smooth transition into onboarding.",
             "Keep up-to-date with employment laws and hiring practices to ensure compliance.",
              "Foster a positive candidate experience throughout the recruitment process."
              
            ]}
            requiredSkills={[
              "2+ years of experience in recruitment or talent acquisition.",
"Hands-on experience with various sourcing techniques (e.g., recruiting on social platforms, professional networks).",
"Proficiency in using Applicant Tracking Systems (ATS) and Human Resource Information Systems (HRIS).",
"Strong interpersonal skills with the ability to build rapport with candidates and internal stakeholders.",
"Knowledge of full-cycle recruiting processes and hiring best practices.",
"Familiarity with employer branding strategies and experience organizing recruitment events is a plus.",
"Strong communication, organizational, and decision-making skills.",
"Ability to work in a fast-paced environment and manage multiple open roles simultaneously.",
"Attention to detail and commitment to maintaining compliance."
            ]}
            category="Human Resources"
            jobType="Full Time" 
            location="Surat"
            image="images/default-image.jpg"
          />
        </div>
      </div>
    </div>
  );
}
export default HRExecutiveRecruiter;