import React from "react";
import JobDescription from "./JobDescription";
import JobApplicationForm from "./JobApplicationForm";

function FlutterDeveloper() {
  return (
    <div className="container">
      <div className="row pt-5 pt-lg-0 pb-5 my-5">
        <div className="col-md-12 wp-bp-content-width">
          <JobDescription
            title="Flutter App Development"
            skills={[
              "Flutter",
              "Dart",
              "Redux",
              "Flutter Hooks",
              "Firebase",
              "JavaScript",
              "REST APIs",
            ]}
            responsibilities={[
              "Develop high-quality, scalable, and reusable UI components using Flutter.",
              "Develop responsive user interfaces and cross-platform mobile applications.",
              "Participate in daily scrum, sprint planning, reviews, demos, retrospectives, and grooming sessions.",
              "Adhere to the organizational guidelines and processes.",
              "Write well-designed and efficient code following best practices, design patterns, and secure mobile development guidelines.",
            ]}
            requiredSkills={[
              "2+ years of experience in mobile application development.",
              "Expert in Flutter, Dart, state management (Redux or Provider), and Flutter Hooks.",
              "1+ years of experience in developing Flutter applications for both Android and iOS.",
              "Knowledge of integrating native libraries and modules with Flutter.",
              "Familiarity with native build tools like XCode, Android Studio, and Gradle.",
              "Knowledge to design, build, and maintain high performance, reusable, and reliable Flutter code.",
              "Strong understanding of Android and iOS ecosystems, including app release processes (App Store, Google Play).",
              "Demonstrated interest in mobile technology, user experience, and analytical problem-solving.",
              "Experience with eCommerce mobile app development is a huge plus.",
              "Strong written and verbal communication skills.",
            ]}
            category="Development"
            jobType="Full Time"
            location="Surat"
            image="images/default-image.jpg"
          />

          <div className="mt-4">
            <JobApplicationForm role=".NET App Development" />
          </div>
        </div>
      </div>
    </div>
  );
}
export default FlutterDeveloper;