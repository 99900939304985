import React from "react";
import JobDescription from "./JobDescription";
function AndroidDeveloper() {
  return (
    <JobDescription
      title="Android App Development"
      skills={[
        "Android SDK",
        "Kotlin",
        "Java",
        "REST APIs",
        "Firebase",
        "Android Studio",
        "Git",
        "XML",
        "JSON",
        "SQLite",
        "MVVM Architecture",
        "Material Design",
      ]}
      responsibilities={[
        "Design, build, and maintain high-performance,reusable, and reliable Android applications.",
        "Collaborate with cross-functional teams to define, design, and ship new features.",
        "Work on bug fixing and improving application performance.",
        "Ensure the best possible performance, quality, and responsiveness of the application.",
        "Write clean, maintainable, and scalable code,following best coding practices.",
        "Use the latest libraries and APIs to enhance the performance and features of the application.",
        "Stay updated with the latest industry trends,tools, and technologies for Android development.",
       "Continuously discover, evaluate, and implement new technologies to maximize development efficiency."
                                       
      ]}
      requiredSkills={[
        "2+ years of experience in Android app development.",
        " Proficient in Android SDK, Kotlin, and Java with hands-on experience in Android Studio.",
        "Experience with third-party libraries, REST APIs, and integrating with backend services.",
        "Knowledge of architecture patterns like MVVM and experience in building scalable applications.",
        "Familiarity with Google Play Store guidelines and app deployment process.",
        " Experience with Firebase, push notifications,and cloud message APIs.",
        "Strong understanding of Material Design principles and Android UI/UX best practices.",
        "Strong problem-solving skills and attention to detail.",
        "Experience in writing unit tests and automated testing frameworks.",
      ]}
      category="Development"
      jobType="Full Time"
      location="Surat"
      image="images/default-image.jpg"
    />
  );
}

export default AndroidDeveloper;