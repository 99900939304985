import React from "react";
import { Link } from "react-router-dom";
export default function NotFound() {
  return (
    <>
      <div id="content" className="site-content">
        <div className="container">
          <div className="row mt-5 mb-5">
            <div className="col-md-12 text-center pb-5">
              <h1>Page not Found</h1>
              <p>The page you are looking for not available!</p>
              <Link to="/" className="btn btn-primary btn-round mt-3">
                Go to Home
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
