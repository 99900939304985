import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
const GoToTop = () => {
  const [isVisible, setIsVisible] = useState(false);
  const handleScroll = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };
  const scrollToTop = (e) => {
    e.preventDefault();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <div>
      {isVisible && (
        <Link to="/" onClick={scrollToTop} id="go-to-top">
          <canvas className="arrow"></canvas>
        </Link>
      )}
    </div>
  );
};
export default GoToTop;
