import React from "react";
// import { Link } from 'react-router-dom'

export default function EnterpriseSolution() {
  return (
    <>
      <section className="enterprise-solution">
        <div className="container wow zoomIn">
          <div className="row">
            <div className="col-md-12">
              <div className="solutionblocks">
                <div className="sblocks bg1">
                  <h3>Enterprise Solution</h3>
                  <p>
                    Enhancing enterprise experience through the development of
                    innovative and impactful web, mobile, and internet solutions
                    with a rich user experience.
                  </p>
                  <img
                    className="simg"
                    src="../../wp-content/themes/wp-bootstrap-4/assets/images/enterprise.jpg"
                    alt="Enterprise Solution"
                  />
                  <ul>
                    <li>Mobile Strategy</li>
                    <li>ERP System</li>
                    <li>Technical Consulting</li>
                    <li>Enterprise Mobility</li>
                    <li>Content Mobility</li>
                    <li>Mobile App Platforms</li>
                  </ul>
                </div>
                <div className="sblocks bg2">
                  <h3>Startup Solution</h3>
                  <p>
                    We assist startups of all sizes, regardless of stage, in
                    nurturing, building, and growing their amazing ideas on
                    mobile and web.
                  </p>
                  <img
                    className="simg"
                    src="../../wp-content/themes/wp-bootstrap-4/assets/images/startup.jpg"
                    alt="startup"
                  />
                  <ul>
                    <li>Idea to prototype</li>
                    <li>MVP Launch</li>
                    <li>Development Services</li>
                    <li>Create launch machine</li>
                    <li>Support and Maintenance</li>
                    <li>Equity Partnership</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
