import React from "react";
import TechnologiesSection from "../sections/TechnologiesSection";
import ConnectUs from "../sections/ConnectUs";
import OurExpertise from "../sections/OurExpertise";
import Navbar from "../sections/Navbar/Navbar";
import { Link } from "react-router-dom";
import useNavigateWithScroll from "../Hooks/useNavigateWithScroll";
const Home = () => {
  const navigateWithScroll = useNavigateWithScroll();
  return (
    <>
      <div className="home page-template page-template-pages page-template-home page-template-pageshome-php page page-id-6196-bp-front-page">
        <div id="page" className="site">
          {/* Responsive Navbar */}
          <Navbar />
          {/* <!-- #masthead --> */}
          <div id="content" className="site-content">
            {/* <Navbar /> */}
            <section className="pagebanner">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-md-7">
                    <div className="text-white banner-titles">
                      <h1 className="wow fadeInRight" data-wow-delay="0.2s">
                        Welcome to 3Dottt Technologies
                      </h1>
                      <p className="wow fadeInRight" data-wow-delay="0.3s">
                        Our outsource software development company provides
                        innovative solutions for app modernization, business
                        restructuring, develops digital strategy and apps, IT
                        modernization, and enhances user experience.
                      </p>

                      <div className="wow fadeInRight" data-wow-delay="0.4s">
                        <Link
                          to="/about-us"
                          className="btn btn-round btn-primary mt-4"
                        >
                          More about us
                          <img
                            className="ml-2"
                            src="/wp-content/themes/wp-bootstrap-4/assets/images/icons/arrow-right.svg"
                            alt="right"
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="banner-img text-right wow fadeInRight">
                      <img
                        className="w-100 floating"
                        src="/wp-content/themes/wp-bootstrap-4/assets/images/laptop-art.png"
                        alt="3dottt software development"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="mouse_scroll">
                <div className="mouse">
                  <div className="wheel"></div>
                </div>
                <div>
                  <span className="m_scroll_arrows unu"></span>
                  <span className="m_scroll_arrows doi"></span>
                  <span className="m_scroll_arrows trei"></span>
                </div>
              </div>
            </section>
            <section className="about-info">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="section-title text-center wow fadeInUp">
                      <h2>Welcome to 3Dottt Technologies</h2>
                      <div className="dividebar"></div>
                    </div>
                  </div>
                </div>
                <div className="row align-items-center">
                  <div className="col-md-12 col-lg-6">
                    <div className="company-statics">
                      <div className="staticbox sb1 wow bounceIn" data-wow-delay="0.2s">
                        <img
                          src="/wp-content/themes/wp-bootstrap-4/assets/images/icons/earth.svg"
                          alt="Countries"
                        />
                        <h3>02</h3>
                        <p>Countries</p>
                      </div>
                      <div className="staticbox sb2 wow bounceIn" data-wow-delay="0.3s">
                        <img
                          src="/wp-content/themes/wp-bootstrap-4/assets/images/icons/folder-open.svg"
                          alt="Countries"
                        />
                        <h3>20 +</h3>
                        <p>Project Done</p>
                      </div>
                      <div className="staticbox sb3 wow bounceIn" data-wow-delay="0.4s">
                        <img
                          src="/wp-content/themes/wp-bootstrap-4/assets/images/icons/users.svg"
                          alt="Countries"
                        />
                        <h3>10 +</h3>
                        <p>Clients</p>
                      </div>
                      <div className="staticbox sb4 wow bounceIn" data-wow-delay="0.4s">
                        <img
                          src="/wp-content/themes/wp-bootstrap-4/assets/images/icons/ribbon.svg"
                          alt="Countries"
                        />
                        <h3>06 +</h3>
                        <p>Years Experience</p>
                      </div>
                      <div className="staticbox sb5 wow bounceIn" data-wow-delay="0.5s">
                        <img
                          src="/wp-content/themes/wp-bootstrap-4/assets/images/icons/users-cog.svg"
                          alt="Countries"
                        />
                        <h3>20 +</h3>
                        <p>Employees</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-6">
                    <div className="about-dessciption wow">
                      <h3 className="wow fadeInRight" data-wow-delay="0.2s">3Dottt As Your Business Partner?</h3>
                      <p className="wow fadeInRight" data-wow-delay="0.3s">
                        With more than 6 years of experience, we have seen the
                        IT realm transform rapidly. Our experience in rendering
                        risk-free digital solutions puts us in a different
                        league from our peers. Moreover, we employ complete
                        transparency, robust communication, Agile methodology,
                        and provide superlative IT and development solutions.
                      </p>
                      <p className="wow fadeInRight" data-wow-delay="0.4s">
                        3dottt mastery of the latest technologies like chatbots
                        and cloud services is guaranteed to make your business
                        STAND OUT!
                      </p>
                      <div className="wow fadeInRight" data-wow-delay="0.5s">
                        <Link
                          to="/about-us"
                          className="btn btn-round btn-primary"
                          onClick={(e) => {e.preventDefault();navigateWithScroll('/about-us');}}
                        >
                          Read more
                          <img
                            className="ml-2"
                            src="/wp-content/themes/wp-bootstrap-4/assets/images/icons/arrow-right.svg"
                            alt="right"
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div className="shapes-bg">
              <img
                src="/wp-content/themes/wp-bootstrap-4/assets/images/icons/shape-1.svg"
                alt="right"
              />
            </div>
            <OurExpertise />
            <TechnologiesSection />
            <ConnectUs />
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
