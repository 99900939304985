import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SelectBox from "../Pages/Jobs/SelectBox";
import { Link } from "react-router-dom";

const ConnectUs = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    description: "",
    services: "",
  });

  const [errors, setErrors] = useState({
    fullName: "",
    email: "",
    phone: "",
  });

  const [disable, setDisable] = useState(false);

  const validate = () => {
    const newErrors = {};

    // Full Name validation
    if (!formData.fullName.trim()) {
      newErrors.fullName = "Full Name is required.";
    }

    // Email validation
    if (!formData.email.trim()) {
      newErrors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Please enter a valid email address.";
    }

    // Phone validation
    if (!formData.phone.trim()) {
      newErrors.phone = "Phone number is required.";
    } else if (!/^\d{10}$/.test(formData.phone)) {
      newErrors.phone = "Phone number must be 10 digits.";
    }

    // Description validation
    if (!formData.description.trim()) {
      newErrors.description = "Project description is required.";
    }

    // Services validation
    if (!formData.services.trim()) {
      newErrors.services = "Please select a service.";
    }

    setErrors(newErrors);
    // return Object.keys(newErrors).length === 0;
    return newErrors;
  };
  const handleChange = (name, value) => {
    console.log(name);
    if (typeof name === "object") {
      // Handle event from regular input fields
      const { name: fieldName, value: fieldValue } = name.target;
      setFormData({
        ...formData,
        [fieldName]: fieldValue,
      });
      // Clear errors on input change
      setErrors({
        ...errors,
        [fieldName]: "",
      });
    } else {
      // Handle direct value changes like SelectBox
      setFormData({
        ...formData,
        [name]: value,
      });
      setErrors({
        ...errors,
        [name]: "",
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisable(true);
    const isValid = validate();
    if (Object.keys(isValid).length === 0) {
      toast.info("Form submitting...", { autoClose: 7000 });
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/submit/contact-us`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to submit form");
        }

        const data = await response.json();
        console.log("Success:", data);
        toast.success("Form submitted successfully!");

        // Clear form data after successful submission
        setFormData({
          fullName: "",
          email: "",
          phone: "",
          description: "",
          services: "",
        });
      } catch (error) {
        // Show an error alert with the error message
        toast.error("Error submitting the form: " + error.message);
      }
    } else {
      setErrors(isValid);
    }
    setDisable(false);
  };

  return (
    <section className="connect-us">
      <div className="container ">
        <div className="row align-items-center">
          <div className="col-md-12">
            <div className="section-title text-center wow fadeInUp">
              <h2 className="mt-3">How can we help you?</h2>
              <div className="dividebar"></div>
            </div>
          </div>

          {/* co details */}
          <div className="col-md-12 col-lg-7  ">
            <div
              className="contact-information wow fadeInLeft"
              // style={{ marginBottom: "130px" }}
            >
              <h5>
                <img
                  src="wp-content/themes/wp-bootstrap-4/assets/images/icons/contact/phonebook.png"
                  alt="3Dottt Email & Phone"
                />
                Email & Phone
              </h5>

              <ul>
                <li>
                  <img
                    // src="wp-content/themes/wp-bootstrap-4/assets/images/icons/contact/mail.png"
                    src="wp-content/themes/wp-bootstrap-4/assets/images/icons/icon-mail-blue.svg"
                    alt="3Dottt email id"
                  />
                  {/* <span>info@3dottt.com</span> */}
                  <Link to="mailto:info@3dottt.com">
                    <span style={{ color: "#04347b" }}>info@3dottt.com</span>
                  </Link>
                </li>
                <li>
                  <img
                    src="wp-content/themes/wp-bootstrap-4/assets/images/icons/contact/phone.png"
                    alt="3Dottt phone number"
                  />
                  <span style={{ color: "#04347b" }}>+91 7016647130</span>
                </li>
              </ul>

              <h5 className="" style={{ marginTop: "50px" }}>
                <img
                  src="wp-content/themes/wp-bootstrap-4/assets/images/icons/contact/world.png"
                  alt="3Dottt world location"
                />
                Locations
              </h5>
              <ul className="">
                <li>
                  <img
                    src="wp-content/themes/wp-bootstrap-4/assets/images/icons/india.png"
                    alt="3Dottt in india"
                  />
                  <span>
                    <strong>Surat (India)</strong>
                    <br />
                    1098, Silver Business Point, Near VIP Circle, Uttran, Surat,
                    Gujarat 394105
                  </span>
                </li>
              </ul>
            </div>
          </div>

          {/* contact from */}
          <div className="col-md-12 col-lg-5 mt-5 ">
            <div className="contacts wow fadeInRight">
              <h3>Let us contact you</h3>
              <p>Anything On your Mind. We'll Be Glad To Assist You!</p>
              <div className="contactformwrapper">
                <div
                  className="wpcf7 no-js"
                  id="wpcf7-f27-o1"
                  lang="en-US"
                  dir="ltr"
                >
                  <div className="screen-reader-response">
                    <p role="status" aria-live="polite" aria-atomic="true"></p>
                    <ul></ul>
                  </div>

                  <form onSubmit={handleSubmit} className="contacts">
                    <div className="contactbox">
                      <div className="row">
                        <div className="col-xl-12">
                          <div className="col-xl-12">
                            <div
                              className={`form__group field ${
                                errors.email && "error"
                              }`}
                            >
                              <input
                                type="text"
                                name="fullName"
                                value={formData.fullName}
                                onChange={handleChange}
                                className="wpcf7-form-control"
                                placeholder=" "
                              />
                              <label htmlFor="fullName" className="form__label">
                                Full Name
                                <span className="required-asterisk"> *</span>
                              </label>
                              {errors.fullName && (
                                <p className="error-message">
                                  {errors.fullName}
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="col-xl-12">
                            <div
                              className={`form__group field ${
                                errors.email && "error"
                              }`}
                            >
                              <input
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                className="wpcf7-form-control"
                                placeholder=" "
                              />
                              <label htmlFor="email" className="form__label">
                                Email
                                <span className="required-asterisk"> *</span>
                              </label>
                              {errors.email && (
                                <p className="error-message">{errors.email}</p>
                              )}
                            </div>
                          </div>

                          <div className="col-xl-12">
                            <div
                              className={`form__group field ${
                                errors.phone && "error"
                              }`}
                            >
                              <input
                                type="text"
                                name="phone"
                                value={formData.phone}
                                onChange={handleChange}
                                className="wpcf7-form-control"
                                placeholder=" "
                              />
                              <label htmlFor="phone" className="form__label">
                                Phone Number
                                <span className="required-asterisk"> *</span>
                              </label>
                              {errors.phone && (
                                <p className="error-message">{errors.phone}</p>
                              )}
                            </div>
                          </div>

                          <div className="col-lg-12">
                            <div
                              className={`form__group field ${
                                errors.services && "error"
                              }`}
                            >
                              <SelectBox
                                options={[
                                  { value: "", label: "Select a Service *" },
                                  {
                                    value: "Website Development",
                                    label: "Website Development",
                                  },
                                  {
                                    value: "Mobile App Development",
                                    label: "Mobile App Development",
                                  },
                                  {
                                    value: "Product Engineering",
                                    label: "Product Engineering",
                                  },
                                  {
                                    value: "Hire Dedicated Resources",
                                    label: "Hire Dedicated Resources",
                                  },
                                  {
                                    value: "Cloud Services",
                                    label: "Cloud Services",
                                  },
                                  {
                                    value: "Chatbot Development",
                                    label: "Chatbot Development",
                                  },
                                  { value: "Others", label: "Others" },
                                ]}
                                value={formData.services}
                                onChange={(value) =>
                                  handleChange("services", value)
                                }
                                placeholder="Select a Service *"
                              />
                              {errors.services && (
                                <p className="error-message">
                                  {errors.services}
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="col-xl-12 pt-2">
                            <div
                              className={`form__group field ${
                                errors.description && "error"
                              }`}
                            >
                              <input
                                name="description"
                                value={formData.description}
                                onChange={handleChange}
                                // onBlur={handleBlur}
                                className="wpcf7-form-control"
                                // rows="5"
                                placeholder=" "
                              />
                              <label
                                htmlFor="description"
                                className="form__label"
                              >
                                Project Description
                                <span className="required-asterisk"> *</span>
                              </label>
                              {errors.description && (
                                <p className="error-message">
                                  {errors.description}
                                </p>
                              )}
                            </div>
                          </div>

                          <div className="col-xl-12">
                            <div className="submitbtn form__group">
                              <input
                                type="submit"
                                value="Submit"
                                className={`wpcf7-form-control submit-btn ${
                                  disable && "cursor-disable"
                                }`}
                                disabled={disable}
                              />
                              <ToastContainer />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ConnectUs;
