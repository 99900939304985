import React from "react";
import TechnologiesSection from "../../../sections/TechnologiesSection";
import Breadcrumbs from "../../../components/Breadcrumbs";
import OurExpertise from "../../../sections/OurExpertise";

export default function Services() {
  return (
    <>
      <div id="content" className="site-content">
        <Breadcrumbs
          title="Services"
          paths={[{ name: "Home", url: "/" }, { name: "Services" }]}
        />
        <section className="aboutus-section">
          <div className="container wow slideInUp">
            <div className="row">
              <div className="col-md-12 mt-3">
                <div className="section-title text-center">
                  <h2>Affordable It Services We Offer</h2>
                  <div className="dividebar"></div>
                </div>
              </div>
              <div className="col-md-12 mt-3">
                <p>
                  3Dottt Technologies adopts the best practices to deliver
                  secure, robust, and scalable software development services to
                  startups, SMEs, and enterprises.
                </p>
                <p>
                  Our software designing & development company let business
                  revolutionize and gain a competitive edge with the expert
                  delivery of creative, tailor-made software. Our expert team of
                  professionals has offered 100+ customized software solutions
                  by employing modern technologies like IoT, AR/VR, Artificial
                  Intelligence and Machine Learning.
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* Our Expertise Section */}
        <OurExpertise />
        <TechnologiesSection />
      </div>
    </>
  );
}
