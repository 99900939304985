import React from "react";
import EnterpriseSolution from "../../../sections/Services/EnterpriseSolution";
import Breadcrumbs from "../../../components/Breadcrumbs";
export default function ProductEngineering() {
  return (
    <>
      <div id="content" className="site-content">
        <Breadcrumbs
          title="Product Engineering"
          paths={[
            // { name: "Home", url: "/" },
            { name: "Services", url: "/services" },
            { name: "Product Engineering" },
          ]}
        />
        <section className="aboutus-section">
          <div className="container wow fadeInUp">
            <div className="row">
              <div className="col-md-10 mx-auto mt-3">
                <div className="section-title text-center">
                  <h2>
                    Enhance Your Digital Experience through Product Engineering
                  </h2>
                  <div className="dividebar"></div>
                  <p className="mt-3">
                    Are you looking for complete software product development
                    that perfectly fits your company’s needs? You’re in the
                    right place. 3Dottt Technologies is one of the top product
                    engineering companies that have helped numerous enterprises,
                    product startups, and SMEs develop some of the best
                    end-to-end product solutions on the market.
                  </p>
                </div>
              </div>
            </div>
            <div className="row align-items-center mt-5">
              <div className="col-md-5">
                <p>
                  3Dottt Technologies is a leading Product Engineering
                  Development Company with a team of highly skilled product
                  design engineers, product development engineers, and a
                  one-stop place for all the talents your business needs for a
                  digital transformation through fully managed product
                  development services. Collaborating with us means you will
                  automatically get:
                </p>
                <ul style={{ paddingLeft: "20px" }}>
                  <li>
                    Access to highly Certified Product Engineering Specialists
                  </li>
                  <li>Flexible Engagement and Hiring Models</li>
                  <li>Agile and SCRUM Development Process</li>
                  <li>Strict Non-Disclosure Agreement Policies</li>
                </ul>
              </div>
              <div className="col-md-6 ml-auto">
                <img
                  src="../../wp-content/uploads/2022/03/pro-eng.png"
                  alt="chatbot services"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="whyusproduct-section">
          <div className="container wow zoomIn">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="prodcard">
                  <img
                    src="../../wp-content/themes/wp-bootstrap-4/assets/images/pattern.png"
                    alt="3Dottt Technologies"
                  />
                  <h2>
                    Why choose 3Dottt Technologies as your
                    <span className="text-primary">
                      Product Engineering Company?
                    </span>
                  </h2>
                  <p>
                    Irrespective of the scale, size, importance, or urgency of
                    your software product development project, your approach
                    will only succeed through proper planning and processes to
                    get the best-fitted software development applications for
                    your organizational needs. You will have to collaborate with
                    a reliable Product Engineering Firm that follows product
                    engineering’s best practices, is well equipped and aware of
                    product engineering tools and technologies, and has a team
                    of proficient software product engineers.
                  </p>
                  <p className="mb-0">
                    3Dottt, known as the best Product Engineering Company and
                    the most preferred offshoring engineering partner for
                    various organizations globally, has helped many product
                    startups, enterprises, and SMEs develop unparalleled IT
                    products through our product engineering services, and
                    custom product engineering software solutions. Our team of
                    product development specialists don’t believe in
                    one-size-fits-all solutions. Rather, our approach focuses on
                    understanding your custom project requirements in detail,
                    planning an effective product engineering roadmap, then
                    working on your project in the most cost-effective,
                    efficient, and dedicated way possible.
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="pl-md-4">
                  <h3>
                    Hire Product Development Engineers from 3Dottt Technologies
                  </h3>
                  <p>
                    Hiring a full-stack product engineering solution does not
                    only require coders who more likely have a limited view on
                    the project. Instead, for a successful product development,
                    what you really need is to hire a dedicated product
                    engineering team that looks at the full picture to cover the
                    process of software product development from all aspects. By
                    hiring our software product development team at 3Dottt
                    Technologies, you will have years of expertise and the best
                    practices in software product development at your
                    fingertips, delivering the fittest product engineering
                    solution for your business needs.
                  </p>
                  <ul style={{ paddingLeft: "20px" }}>
                    <li>Product Development Manager</li>
                    <li>Product Design Engineer</li>
                    <li>Prototype Engineer</li>
                    <li>Product Development Consultant</li>
                    <li>Product Development Specialist</li>
                    <li>Product Line Engineer</li>
                    <li>Performance Software Engineer</li>
                    <li>Remote Product Managers</li>
                    <li>Product Management Engineer</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Enterprise Solution  */}
        <EnterpriseSolution />
      </div>
    </>
  );
}
