import React from "react";
import JobDescription from "./JobDescription";
function MernStackDeveloper() {
  return (
    <div className="container">
      <div className="row pt-5 pt-lg-0 pb-5 my-5">
        <div className="col-md-12 wp-bp-content-width">
          <JobDescription
            title="MERN Stack Developer"
            skills={[
              "MongoDB", "Express.js", "React", "Node.js", "JavaScript", "HTML", "CSS", "RESTful APIs"
            ]}
            responsibilities={[
              "Design, develop, and maintain applications using the MERN stack (MongoDB, Express.js, React, Node.js).",
              "Collaborate with front-end developers to create responsive user interfaces.",
              "Build and consume RESTful APIs to connect the front-end with back-end services.",
              "Optimize applications for maximum performance and scalability.",
              "Write clean, maintainable, and well-documented code.",
              "Participate in code reviews to uphold coding standards and best practices.",
              "Stay updated with the latest trends and technologies in web development.",
              "Work in an Agile environment, participating in daily stand-ups and sprint planning.",
              
            ]}
            requiredSkills={[
"2+ years of experience in web development using the MERN stack.",
"Strong proficiency in MongoDB, Express.js, React, and Node.js.",
"Experience with front-end technologies such as HTML5, CSS3, and JavaScript.",
"Familiarity with RESTful APIs and web services.",
"Understanding of version control systems, particularly Git.",
"Strong analytical and problem-solving skills.",
"Excellent communication and teamwork abilities.",
            ]}
            category="Development"
            jobType="Full Time" 
            location="Surat"  
            image="images/default-image.jpg"
          />
        </div>
      </div>
    </div>
  );
}
export default MernStackDeveloper;
