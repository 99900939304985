import React from "react";
import JobDescription from "./JobDescription";
function PHPDeveloper() {
  return (
    <div className="container">
      <div className="row pt-5 pt-lg-0 pb-5 my-5">
        <div className="col-md-12 wp-bp-content-width">
          <JobDescription
            title="PHP Developer"
            skills={[
              "PHP", "Laravel", "MySQL", "JavaScript", "HTML5", "CSS3", "Git", "RESTful APIs"
            ]}
            responsibilities={[
"Develop, test, and maintain high-quality PHP applications and frameworks.",
"Collaborate with front-end developers to integrate user-facing elements with server-side logic.",
"Design and implement scalable and secure RESTful APIs for data exchange.",
"Write clean, maintainable code following best practices and standards.",
"Troubleshoot and resolve application issues and bugs in a timely manner.",
"Participate in code reviews and ensure adherence to coding standards.",
"Stay up-to-date with emerging technologies and trends in PHP development.",
"Work in an Agile environment, participating in daily stand-ups and sprint planning.",
            ]}
            requiredSkills={[
"2+ years of experience in PHP development.",
"Strong knowledge of PHP frameworks, particularly Laravel or CodeIgniter.",
"Experience with MySQL and database design principles.",
"Proficiency in front-end technologies like JavaScript, HTML5, and CSS3.",
"Familiarity with version control systems, especially Git.",
"Experience in building and consuming RESTful APIs.",
"Strong analytical and problem-solving skills.",
"Excellent communication and collaboration skills.",
            ]}
            category="Development"
            jobType="Full Time" 
            location="Surat"  
            image="images/default-image.jpg"
          />
        </div>
      </div>
    </div>
  );
}
export default PHPDeveloper;