import React from "react";
import JobDescription from "./JobDescription";
function FullStackDeveloper() {
  return (
    <div className="container">
      <div className="row pt-5 pt-lg-0 pb-5 my-5">
        <div className="col-md-12 wp-bp-content-width">
          <JobDescription
            title="Full Stack Developer"
            skills={[
              "JavaScript", "Node.js", "React", "Express.js", "MongoDB", "REST APIs", "HTML", "CSS", "Git", "Docker"
            ]}
            responsibilities={[
              "Develop high-quality, scalable, and reusable front-end and back-end code.",
              "Design and build user interfaces using modern web technologies like React and JavaScript.",
             " Develop server-side applications using Node.js and Express.js.",
              "Create and manage databases using MongoDB or other relational and non-relational databases.",
             " Participate in daily scrum, sprint planning, reviews, demos, retrospectives, and grooming sessions.",
              "Adhere to organizational guidelines and follow best practices in web application security and performance.",
              "Collaborate with UI/UX designers, product managers, and other developers to deliver a seamless user experience.",
              
            ]}
            requiredSkills={[
              "2+ years of experience in full-stack web development.",
             "Proficiency in front-end technologies like React, JavaScript (ES6+), HTML, CSS.",
                  "Strong experience in back-end development using Node.js, Express.js, and MongoDB or similar databases.",
            "Knowledge of RESTful APIs, version control (Git), and CI/CD pipelines.",
         "Familiarity with containerization tools like Docker and cloud services like AWS, GCP, or Azure is a plus.",
       "Understanding of front-end build tools like Webpack or Parcel.",
      "Strong problem-solving skills and ability to debug and optimize performance.",
     "Familiarity with Agile methodologies and working in cross functional teams.",
    "Strong written and verbal communication skills."
            ]}
            category="Development"
            jobType="Full Time"
            location="Surat"
            image="images/default-image.jpg"
          />
        </div>
      </div>
    </div>
  );
}
export default FullStackDeveloper;
