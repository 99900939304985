import React from "react";
import JobDescription from "./JobDescription";
function FlutterFlowDeveloper() {
  return (
    <div className="container">
      <div className="row pt-5 pt-lg-0 pb-5 my-5">
        <div className="col-md-12 wp-bp-content-width">
          <JobDescription
            title="FlutterFlow Development"
            skills={[
              "FlutterFlow",
              "Firebase",
              "Dart",
              "No-code Platforms",
              "REST APIs",
            ]}
            responsibilities={[
              "Design and develop scalable and responsive applications using FlutterFlow.",
              "Integrate backend services and REST APIs with FlutterFlow applications.",
              "Create pixel-perfect UI designs based on provided wireframes or guidelines.",
              "Participate in agile ceremonies such as daily stand-ups and sprint planning.",
              "Debug and resolve application issues promptly.",
            ]}
            requiredSkills={[
              "2+ years of experience in no-code or low-code application development.",
              "Strong proficiency with FlutterFlow and Firebase integrations.",
              "Understanding of Dart programming and custom code integration in FlutterFlow.",
              "Knowledge of best practices for UI/UX design in mobile and web applications.",
              "Experience in publishing applications to App Store and Google Play.",
              "Excellent problem-solving and communication skills."
            ]}
            category="Development"
            jobType="Full Time"
            location="Surat"
            image="images/default-image.jpg"
          />
        </div>
      </div>
    </div>
  );
}
export default FlutterFlowDeveloper;