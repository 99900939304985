import React, { useEffect, useRef, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
const JobApplicationForm = ({ role }) => {
  const genderRef = useRef(null);
  const [isGenderOpen, setIsGenderOpen] = useState(false);
  // Form fields state
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    gender: "",
    resume: null,
    year: "0",
    month: "0",
    availableToJoin: "",
    currentLocation: "",
    role: role && role,
  });
  // Form errors state
  const [formErrors, setFormErrors] = useState({
    fullName: "",
    email: "",
    phone: "",
    gender: "",
    availableToJoin: "",
    year: "",
    currentLocation: "",
    resume: "",
  });
  // Handle Input Change and perform validation
  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log(`${name} :${value}`);
    // validations
    if (name === "year" && (value < 0 || value > 60)) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        year: "year is below 60",
      }));
      return;
    }
    setFormData({ ...formData, [name]: value });
    // console.log("saved value");
    // Real-time validation
    let error = "";
    switch (name) {
      case "fullName":
        if (!value) error = "Full Name is required.";
        break;
      case "email":
        if (!value) {
          error = "Email is required.";
        } else if (!/\S+@\S+\.\S+/.test(value)) {
          error = "Email is invalid.";
        }
        break;
      case "phone":
        if (!value) {
          error = "Phone is required.";
        } else if (!/^\d{10}$/.test(value)) {
          error = "Phone number must be 10 digits.";
        }
        break;
      case "gender":
        if (!value) error = "Gender is required.";
        break;
      case "availableToJoin":
        if (!value) error = "Available To Join date is required.";
        break;
      case "year":
        if (!value) error = "Year is required.";
        break;
      case "currentLocation":
        if (!value) error = "Current Location is required.";
        break;
      // 55
      default:
        break;
    }

    setFormErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
  };
  const handleFileChange = (e) => {
    // console.log(e.target.files[0].name)
    setFormData({ ...formData, resume: e.target.files[0] });
    if (!e.target.files[0]) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        resume: "Resume is required.",
      }));
    } else if (e.target.files[0].size > 10 * 1024 * 1024) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        resume: "Resume size must be less than 10MB.",
      }));
    } else {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        resume: "",
      }));
    }
  };
  const handleGenderChange = (gender) => {
    setFormData((prevData) => ({ ...prevData, gender }));
    setFormErrors((prevErrors) => ({ ...prevErrors, gender: "" }));
    setIsGenderOpen(false);
  };
  // Handle Click Outside to close gender dropdown
  const handleClickOutside = (event) => {
    if (genderRef.current && !genderRef.current.contains(event.target)) {
      setIsGenderOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  // Min and Max Date setup for availableToJoin date
  const [minDate, setMinDate] = useState("");
  const [maxDate, setMaxDate] = useState("");
  const [disable, setDisable] = useState(false);
  useEffect(() => {
    const currentDate = new Date();
    const formattedCurrentDate = currentDate.toISOString().split("T")[0];
    setMinDate(formattedCurrentDate);
    const maxDateObj = new Date();
    maxDateObj.setMonth(maxDateObj.getMonth() + 3);
    if (maxDateObj.getDate() !== currentDate.getDate()) {
      maxDateObj.setDate(0);
    }
    const formattedMaxDate = maxDateObj.toISOString().split("T")[0];
    setMaxDate(formattedMaxDate);
  }, []);

  // Validate the entire form before submission
  const validateForm = () => {
    const errors = {};
    if (!formData.fullName) errors.fullName = "Full Name is required.";
    if (!formData.email) {
      errors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email is invalid.";
    }
    if (!formData.phone) {
      errors.phone = "Phone is required.";
    } else if (!/^\d{10}$/.test(formData.phone)) {
      errors.phone = "Phone number must be 10 digits.";
    }
    if (!formData.gender) errors.gender = "Gender is required.";
    if (!formData.availableToJoin)
      errors.availableToJoin = "Available To Join date is required.";
    if (!formData.year) errors.year = "Year is required.";
    if (!formData.currentLocation)
      errors.currentLocation = "Current Location is required.";
    if (!formData.resume) {
      errors.resume = "Resume is required.";
    } else if (formData.resume.size > 10 * 1024 * 1024) {
      errors.resume = "Resume size must be less than 10MB.";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };
  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(formData);
    setDisable(true);
    if (validateForm()) {
      const submitForm = new FormData();
      submitForm.append("fullName", formData.fullName);
      submitForm.append("email", formData.email);
      submitForm.append("phone", formData.phone);
      submitForm.append("gender", formData.gender);
      submitForm.append("resume", formData.resume); // File is handled here
      submitForm.append("year", formData.year);
      submitForm.append("month", formData.month);
      submitForm.append("availableToJoin", formData.availableToJoin);
      submitForm.append("currentLocation", formData.currentLocation);
      submitForm.append("role", formData.role);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/submit/apply-to-job`,
          {
            method: "POST",
            body: submitForm,
          }
        );
        const data = await response.json();

        if (response.ok) {
          toast.success("Form submitted successfully!");
          setFormData({
            fullName: "",
            email: "",
            phone: "",
            gender: "",
            year: "",
            month: "0",
            availableToJoin: "",
            currentLocation: "",
            resume: null,
          });
        } else {
          toast.error(
            data.message || "An error occurred while submitting the form."
          );
        }
      } catch (error) {
        console.error("Error submitting the application:", error);
        toast.error("Network error occurred while submitting the form.");
      }
    }
    setDisable(false);
  };
  return (
    <div className="awsm-job-form mt-3">
      <div className="awsm-job-form-inner">
        <h2>Apply for this position</h2>
        <form
          id="awsm-application-form"
          className="awsm-application-form"
          name="applicationform"
          method="post"
          onSubmit={handleSubmit}
          encType="multipart/form-data"
        >
          {/* fullname */}
          <div className="awsm-job-form-group">
            <label htmlFor="fullName">
              Full Name
              <span className="awsm-job-form-error required-asterisk">*</span>
            </label>
            <input
              type="text"
              className="awsm-job-form-field awsm-job-form-control"
              id="fullName"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
            />
            {formErrors.fullName && (
              <span className="error">{formErrors.fullName}</span>
            )}
          </div>
          {/* email */}
          <div className="awsm-job-form-group">
            <label htmlFor="email">
              Email
              <span className="awsm-job-form-error required-asterisk">*</span>
            </label>
            <input
              type="email"
              className="awsm-job-form-field awsm-job-form-control"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
            {formErrors.email && (
              <span className="error">{formErrors.email}</span>
            )}
          </div>
          {/* Other form fields here */}
          {/* phone */}
          <div className="awsm-job-form-group">
            <label htmlFor="phone">
              Phone
              <span className="awsm-job-form-error required-asterisk">*</span>
            </label>
            <input
              type="number"
              className="awsm-job-form-field awsm-job-form-control"
              id="phone"
              name="phone"
              value={formData.phone}
              maxLength={10} // Limit the length to 10
              onChange={(e) => {
                const { value } = e.target;
                if (/^\d*$/.test(value) && value.length <= 10) {
                  handleChange(e); // Call the existing handleChange function
                }
              }}
            />
            {formErrors.phone && (
              <span className="error">{formErrors.phone}</span>
            )}
          </div>
          {/* gender */}
          <div className="awsm-job-form-group">
            <label>
              Gender
              <span className="awsm-job-form-error required-asterisk">*</span>
            </label>
            <div
              ref={genderRef}
              className="awsm-selectric"
              onClick={() => setIsGenderOpen(!isGenderOpen)}
            >
              {/* <div className="awsm-selectric-label">{selectedGender}</div> */}
              <div className="awsm-selectric-label">
                {formData && formData.gender
                  ? formData.gender
                  : "Select Gender"}
              </div>
              <div
                className={`awsm-selectric-arrow-drop ${
                  isGenderOpen ? "open" : ""
                }`}
              ></div>
              {isGenderOpen && (
                <div className="awsm-selectric-items">
                  <div
                    onClick={() => handleGenderChange("Male")}
                    className="option"
                  >
                    Male
                  </div>
                  <div
                    onClick={() => handleGenderChange("Female")}
                    className="option"
                  >
                    Female
                  </div>
                </div>
              )}
            </div>
            {formErrors.gender && (
              <span className="error">{formErrors.gender}</span>
            )}
          </div>
          {/* Available To Join */}
          <div className="awsm-job-form-group">
            <label htmlFor="availableToJoin">
              Available To Join
              <span className="awsm-job-form-error required-asterisk">*</span>
            </label>
            <input
              type="date"
              className="awsm-job-form-field awsm-job-form-control"
              id="availableToJoin"
              name="availableToJoin"
              value={formData.availableToJoin}
              onChange={handleChange}
              min={minDate}
              max={maxDate}
            />
            {formErrors.availableToJoin && (
              <span className="error">{formErrors.availableToJoin}</span>
            )}
          </div>
          {/* experience */}
          <div className="validation mt-4">
            <label for="workExperience" data-content="Experience">
              Experience
              <span className="awsm-job-form-error required-asterisk">*</span>
            </label>
            <div className="row mx-0">
              <div className="col-sm-6 col-12 pe-sm-4">
                <div
                  className="row border rounded-1 overflow-hidden"
                  style={{ height: "100%" }}
                >
                  <div className="col-6" style={{ padding: 0 }}>
                    <input
                      type="number"
                      name="year"
                      value={formData.year}
                      onChange={handleChange}
                      className="w-100 h-100 border-0 ps-3 text-center"
                      placeholder="0"
                      style={{
                        height: "100%",
                        width: "100%",
                        outline: "none",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      backgroundColor: "#F8F9FA",
                      padding: "6px 13px",
                    }}
                    className="col-6 border-start text-center"
                  >
                    Years
                  </div>
                </div>
              </div>
              {/* Months */}
              <div className="col-sm-6 col-12 pe-sm-3">
                <div
                  className="row border rounded-1 overflow-hidden"
                  style={{ height: "100%" }}
                >
                  <div
                    className="col-6 custom-select-container awsm-selectric"
                    style={{ paddingLeft: "3px", border: "none" }}
                  >
                    <select
                      className="w-100 h-100 border-0 text-center"
                      name="month"
                      value={formData.month}
                      placeholder="Months"
                      onChange={handleChange}
                      style={{
                        height: "100%",
                        width: "100%",
                        textAlign: "center",
                        outline: "none",
                      }}
                    >
                      <option className="" value="0">
                        0
                      </option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                    </select>
                  </div>
                  <div
                    style={{ backgroundColor: "#F8F9FA", padding: "6px 13px" }}
                    className="col-6 border-start text-center"
                  >
                    Months
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                {formErrors.year && (
                  <span className="error">{formErrors.year}</span>
                )}
              </div>
              <div className="col-6"></div>
            </div>
          </div>
          {/*  Current Location */}
          <div className="awsm-job-form-group pt-3">
            <label htmlFor="currentLocation">
              Current Location
              <span className="awsm-job-form-error required-asterisk">*</span>
            </label>
            <input
              type="text"
              name="currentLocation"
              className="awsm-job-form-field awsm-job-form-control"
              id="currentLocation"
              value={formData.currentLocation}
              onChange={handleChange}
            />
            {formErrors.currentLocation && (
              <span className="error">{formErrors.currentLocation}</span>
            )}
          </div>
          {/* Upload resume */}
          <div className="awsm-job-form-group">
            <label htmlFor="resume-upload" className="resume-upload-box">
              <input
                type="file"
                id="resume-upload"
                name="resume"
                className="resume-upload-input"
                accept=".pdf,.doc,.docx"
                onChange={handleFileChange}
              />
              {formData && formData?.resume ? (
                <span className="resume-upload-link">
                  {formData?.resume?.name}
                </span>
              ) : (
                <span className="resume-upload-link">Upload resume</span>
              )}
              <p className="resume-upload-description">
                This will auto-fill the fields below. 10MB max file size
              </p>
            </label>
            {formErrors.resume ? (
              <span className="error">{formErrors.resume}</span>
            ) : (
              formData &&
              formData?.resume && (
                <p className="resume-upload-link" style={{ fontWeight: "800" }}>
                  Upload Successfully
                </p>
              )
            )}
          </div>
          {/* Submit */}
          <div>
            <input
              type="submit"
              name="form_sub"
              disabled={disable}
              id="awsm-application-submit-btn"
              className="awsm-application-submit-btn"
              value="Submit"
              data-response-text="Submitting.."
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default JobApplicationForm;
