import React from "react";
import JobDescription from "./JobDescription";
function ReactNativeDeveloper () {
  return (
    <div className="container">
      <div className="row pt-5 pt-lg-0 pb-5 my-5">
        <div className="col-md-12 wp-bp-content-width">
          <JobDescription
            title="React native developer"
            skills={[
              "React Native", "React", "Redux", "React Hooks APIs", "JavaScript"
            ]}
            responsibilities={[
              "Develop high-quality, scalable, and reusable UI components.",
              "Develop responsive user interface and single-page application.",
              "Participate in daily scrum, sprint planning, reviews, demos, retrospectives, and grooming sessions.",
              "Adhere to the organizational guidelines and processes.",
              "Write well-designed and efficient code following the guidelines, design patterns, industry-proven, and secure web-programming best practices.",
            ]}
            requiredSkills={[
              
              "2+ years of experience in Mobile Application Development.",
              "Expert in React Native, React, Redux (state management), React Hooks, Jest, Cypress / Detox, Web APIs, JavaScript (ES6, ES7).",
              "1+ years of experience in developing React Native.",
              "Knowledge to build and integrate native libraries and modules.",
              "Familiarity with native build tools, like XCode and Android Studio.",
              "Knowledge to design, build, and maintain high performance, reusable, and reliable react-native code.",
              "Strong understanding of Android or iOS ecosystem and their guidelines for app development",
              "Demonstrated interest in technology, technology-related issues, and analytical analysis",
              "Experience with ECommerce Mobile app development is a huge plus",
              "Strong written and verbal communication skills",
              
              
              
              
              
              
            ]}
            category="Development"
            jobType="Full Time" 
            location="Surat"  
            image="images/default-image.jpg"
          />
        </div>
      </div>
    </div>
  );
}
export default ReactNativeDeveloper;