import "./AwsmSelectric.css";
import "./SelectCss.css";
import Breadcrumbs from "../../components/Breadcrumbs";
import { Link } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import useNavigateWithScroll from "../../Hooks/useNavigateWithScroll";
export default function Career() {
  const navigateWithScroll = useNavigateWithScroll();
  const [selectedCategory, setSelectedCategory] = useState("All Job Category");
  const [selectedJobType, setSelectedJobType] = useState("All Job Type");
  const [isCategoryOpen, setIsCategoryOpen] = useState(false); // Separate state for category dropdown
  const [isJobTypeOpen, setIsJobTypeOpen] = useState(false); // Separate state for job type dropdown
  const categoryRef = useRef(null); // Create a ref for the category dropdown
  const jobTypeRef = useRef(null); // Create a ref for the job type dropdown
  
  const handleCategoryChange = (value) => {
    setSelectedCategory(value);
    setIsCategoryOpen(false); // Close category dropdown after selection
  };

  const handleJobTypeChange = (jobType) => {
    setSelectedJobType(jobType);
    setIsJobTypeOpen(false); // Close job type dropdown after selection
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Close dropdowns if clicked outside of either dropdown
      if (categoryRef.current && !categoryRef.current.contains(event.target)) {
        setIsCategoryOpen(false);
      }
      if (jobTypeRef.current && !jobTypeRef.current.contains(event.target)) {
        setIsJobTypeOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [categoryRef, jobTypeRef]);

  const jobs = [
    {
      title: "HR Executive Recruiter",
      category: "Human Resources (HR)",
      location: "Surat",
      jobType: "Full Time",
      link: "jobs/hr-executive-recruiter",
    },
    {
      title: "Business Development Executive",
      category: "Sales and Business Development",
      location: "Surat",
      jobType: "Full Time",
      link: "jobs/business-development-executive",
    },
    {
      title: "Flutter App Developer",
      category: "Mobile App Development",
      location: "Surat",
      jobType: "Full Time",
      link: "jobs/flutter-developer",
    },
    {
      title: "FlutterFlow Developer",
      category: "Mobile App Development",
      location: "Surat",
      jobType: "Full Time",
      link: "jobs/flutterflow-developer",
    },
    {
      title: "React Native Developer",
      category: "Mobile App Development",
      location: "Surat",
      jobType: "Full Time",
      link: "jobs/react-native-developer",
    },
    {
      title: "Android Developer",
      category: "Mobile App Development",
      location: "Surat",
      jobType: "Full Time",
      link: "jobs/android-developer",
    },
    {
      title: "IOS Developer",
      category: "Mobile App Development",
      location: "Surat",
      jobType: "Full Time",
      link: "jobs/ios-developer",
    },
    {
      title: "Ionic Developer",
      category: "Mobile App Development",
      location: "Surat",
      jobType: "Full Time",
      link: "jobs/ionic-developer",
    },
    {
      title: "AngularJS Developer",
      category: "Frontend Development",
      location: "Surat",
      jobType: "Full Time",
      link: "jobs/angularjs-developer",
    },
    {
      title: "ReactJS Developer",
      category: "Frontend Development",
      location: "Surat",
      jobType: "Full Time",
      link: "jobs/reactjs-developer",
    },
    {
      title: "VueJS Developer",
      category: "Frontend Development",
      location: "Surat",
      jobType: "Full Time",
      link: "jobs/vuejs-developer",
    },
    {
      title: "Python Developer",
      category: "Backend Development",
      location: "Surat",
      jobType: "Full Time",
      link: "jobs/python-developer",
    },
    {
      title: "Java Developer",
      category: "Backend Development",
      location: "Surat",
      jobType: "Full Time",
      link: "jobs/java-developer",
    },
    {
      title: "PHP Developer",
      category: "Backend Development",
      location: "Surat",
      jobType: "Full Time",
      link: "jobs/php-developer",
    },
    {
      title: "NodeJS Developer",
      category: "Backend Development",
      location: "Surat",
      jobType: "Full Time",
      link: "jobs/nodejs-developer",
    },
    {
      title: ".NET Developer",
      category: "Backend Development",
      location: "Surat",
      jobType: "Full Time",
      link: "jobs/dotnet-developer",
    },
    {
      title: "MEAN Stack Developer",
      category: "Full Stack Development",
      location: "Surat",
      jobType: "Full Time",
      link: "jobs/mean-stack-developer",
    },
    {
      title: "MERN Stack Developer",
      category: "Full Stack Development",
      location: "Surat",
      jobType: "Full Time",
      link: "jobs/mern-stack-developer",
    },
    {
      title: "Full Stack Developer",
      category: "Full Stack Development",
      location: "Surat",
      jobType: "Full Time",
      link: "jobs/full-stack-developer",
    },
  ];
  const filteredJobs = jobs.filter((job) => {
    return (
      (selectedCategory === "All Job Category" ||
        job.category === selectedCategory) && // Change made here
      (selectedJobType === "All Job Type" || job.jobType === selectedJobType)
    );
  });

  return (
    <>
      <div id="content" className="site-content">
        <Breadcrumbs
          title="Career"
          paths={[{ name: "Home", url: "/" }, { name: "Career" }]}
        />

        <section className="aboutus-section ">
          <div className="container wow fadeInUp">
            {/* title */}
            <div className="section-title text-center mt-3 ">
              <h2>Now Hiring</h2>
              <div className="dividebar"></div>
            </div>

            {/* selectbox */}
            <div className="selecet-container mt-5  ">
              {/* selecetbox1 */}
              <div
                ref={categoryRef}
                className="awsm-selectric me-md-2 mb-md-0 mb-2 "
                style={{ maxWidth: "300px" }}
                onClick={() => {
                  setIsCategoryOpen(!isCategoryOpen);
                  setIsJobTypeOpen(false); // Ensure job type dropdown closes when category is opened
                }}
              >
                <div className="awsm-selectric-label">{selectedCategory}</div>
                <div
                  className={`awsm-selectric-arrow-drop ${
                    isCategoryOpen ? "open" : ""
                  }`}
                ></div>
                {isCategoryOpen && (
                  <div className="awsm-selectric-items ">
                    <div
                      onClick={() => handleCategoryChange("All Job Category")}
                      className="option"
                    >
                      All Job Category
                    </div>
                    <div
                      onClick={() =>
                        handleCategoryChange("Mobile App Development")
                      }
                      className="option"
                    >
                      Mobile App Development
                    </div>
                    <div
                      onClick={() =>
                        handleCategoryChange("Frontend Development")
                      }
                      className="option"
                    >
                      Frontend Development
                    </div>
                    <div
                      onClick={() =>
                        handleCategoryChange("Backend Development")
                      }
                      className="option"
                    >
                      Backend Development
                    </div>
                    <div
                      onClick={() =>
                        handleCategoryChange("Full Stack Development")
                      }
                      className="option"
                    >
                      Full Stack Development
                    </div>
                    <div
                      onClick={() =>
                        handleCategoryChange("Human Resources (HR)")
                      }
                      className="option"
                    >
                      Human Resources (HR)
                    </div>
                    <div
                      onClick={() =>
                        handleCategoryChange("Sales and Business Development")
                      }
                      className="option"
                    >
                      Sales and Business
                    </div>
                  </div>
                )}
              </div>
              {/* selecetbox2 */}
              <div
                ref={jobTypeRef}
                className="awsm-selectric  me-md-2 mb-md-0 mb-2"
                style={{ maxWidth: "300px" }}
                onClick={() => {
                  setIsJobTypeOpen(!isJobTypeOpen);
                  setIsCategoryOpen(false); // Ensure category dropdown closes when job type is opened
                }}
              >
                <div className="awsm-selectric-label">{selectedJobType}</div>
                <div
                  className={`awsm-selectric-arrow-drop ${
                    isJobTypeOpen ? "open" : ""
                  }`}
                ></div>
                {isJobTypeOpen && (
                  <div className="awsm-selectric-items">
                    <div
                      onClick={() => handleJobTypeChange("All Job Type")}
                      className="option"
                    >
                      All Job Type
                    </div>
                    <div
                      onClick={() => handleJobTypeChange("Full Time")}
                      className="option"
                    >
                      Full Time
                    </div>
                    <div
                      onClick={() => handleJobTypeChange("Part Time")}
                      className="option"
                    >
                      Part Time
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* Job Listings */}
            <div className="job-listings  mt-4">
              <div className="awsm-job-listings awsm-lists" data-listings="10">
                {filteredJobs.length === 0  && 
                
                // <h5 className="text-center p-5">Records Not Found</h5>
                <div className=" d-flex justify-content-center " >
                  <img
                  className="img-responsive " style={{marginLeft: "auto !important", marginRight: "auto !important"}}
                  src="/wp-content/themes/wp-bootstrap-4/assets/images/No-records.png"
                  alt="No records found"
                />
                </div>

                }
                {filteredJobs.map((job, index) => (
                  <div
                    key={index}
                    className="awsm-job-listing-item awsm-list-item"
                    id="awsm-list-item-5992"
                  >
                    <div className="awsm-job-item">
                      <div className="awsm-list-left-col">
                        <h2 className="awsm-job-post-title">
                          <Link to={job.link} onClick={(e) => {e.preventDefault();navigateWithScroll(job.link);}}>{job.title}</Link>
                        </h2>
                      </div>
                      {/* moredetails responsive */}
                      <div className="awsm-list-right-col">
                        <div className="awsm-job-specification-wrapper">
                          <div className="awsm-job-specification-item awsm-job-specification-job-category">
                            <span className="awsm-job-specification-term">
                              {job.category}
                            </span>
                          </div>
                          <div className="awsm-job-specification-item awsm-job-specification-job-location">
                            <span className="awsm-job-specification-term">
                              {job.location}
                            </span>
                          </div>
                          <div className="awsm-job-more-container">
                            <Link className="awsm-job-more" to={job.link} onClick={(e) => {e.preventDefault();navigateWithScroll(job.link);}}>
                              <p className="more-details">
                                More Details <span></span>
                              </p>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>

        {/* 3rd section */}
        <section className="aboutus-section">
          <div className="container wow zoomIn">
            <div className="row">
              <div className="col-md-8 mt-3">
                <div className="row">
                  <div className="col-md-12">
                    <div className="section-title text-left wow fadeInUp">
                      <h2>Perks of working with 3Dottt</h2>
                      <div className="dividebar"></div>
                    </div>
                  </div>
                  <div className="col-md-6 wowfadeInRight">
                    <ul className="list-icons">
                      <li className="wow flipInX" data-wow-delay="0.2s">
                        <i className="fa fa-inr"></i>
                        <span>Competitive Salary</span>
                      </li>
                      <li className="wow flipInX" data-wow-delay="0.4s">
                        <i className="fa fa-users"></i>
                        <span>Training & Development</span>
                      </li>
                      <li className="wow flipInX" data-wow-delay="0.6s">
                        <i className="fa fa-envelope-open"></i>
                        <span>Celebration & Events</span>
                      </li>
                      <li className="wow flipInX" data-wow-delay="0.8s">
                        <i className="fa fa-refresh"></i>
                        <span>Continuous Learning</span>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <ul className="list-icons">
                      <li className="wow flipInX" data-wow-delay="1s">
                        <i className="fa fa-trophy"></i>
                        <span>Awards & Recognitions</span>
                      </li>
                      <li className="wow flipInX" data-wow-delay="1.2s">
                        <i className="fa fa-street-view"></i>
                        <span>Outstanding Colleagues</span>
                      </li>
                      <li className="wow flipInX" data-wow-delay="1.4s">
                        <i className="fa fa-building-o"></i>
                        <span>Resourceful Atmosphere</span>
                      </li>
                      <li className="wow flipInX" data-wow-delay="1.6s">
                        <i className="fa fa-pencil-square-o"></i>
                        <span>Exceptional Projects</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mt-3 ">
                <img
                  src="/wp-content/themes/wp-bootstrap-4/assets/images/perk.jpg"
                  alt="Perks of working with 3Dottt Technologies"
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
