import React from "react";
import Breadcrumbs from "../../components/Breadcrumbs";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import useNavigateWithScroll from "../../Hooks/useNavigateWithScroll";
export default function SiteMap() {
  const navigateWithScroll = useNavigateWithScroll();
  const scrollWithOffset = (el) => {
    const yOffset = -200; // Adjust this value to the height of your sticky navbar
    const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: "smooth" });
  };
  return (
    <>
      <div id="content" className="site-content">
        <Breadcrumbs
          title="Site map"
          paths={[{ name: "Home", url: "/" }, { name: "Site map" }]}
        />
        <section className="aboutus-section">
          <div className="container">
            <div className="row mt-4">
              <div className="col-md-4">
                <ul className="list-links">
                  <li>
                    <Link
                      to="/"
                      onClick={(e) => {
                        e.preventDefault();
                        navigateWithScroll("/");
                      }}
                    >
                      <h4>Home</h4>
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="/career"
                      onClick={(e) => {
                        e.preventDefault();
                        navigateWithScroll("/career");
                      }}
                    >
                      <h4>Career</h4>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/contact-us"
                      onClick={(e) => {
                        e.preventDefault();
                        navigateWithScroll("/contact-us");
                      }}
                    >
                      <h4>Contact Us</h4>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col-md-4">
                <ul className="list-links">
                  <li>
                    <Link
                      to="/services"
                      onClick={(e) => {
                        e.preventDefault();
                        navigateWithScroll("/services");
                      }}
                    >
                      <h4>Services</h4>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/services/web-development"
                      onClick={(e) => {
                        e.preventDefault();
                        navigateWithScroll("/services/web-development");
                      }}
                    >
                      Web Development
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/services/mobile-app-development"
                      onClick={(e) => {
                        e.preventDefault();
                        navigateWithScroll("/services/mobile-app-development");
                      }}
                    >
                      Mobile App Development
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/services/ui-ux-design"
                      onClick={(e) => {
                        e.preventDefault();
                        navigateWithScroll("/services/ui-ux-design");
                      }}
                    >
                      UI/UX Design
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/services/ecommerce-development"
                      onClick={(e) => {
                        e.preventDefault();
                        navigateWithScroll("/services/ecommerce-development");
                      }}
                    >
                      E-commerce Development
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/services/product-engineering"
                      onClick={(e) => {
                        e.preventDefault();
                        navigateWithScroll("/services/product-engineering");
                      }}
                    >
                      Product Engineering
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="/services/custom-software-development"
                      onClick={(e) => {
                        e.preventDefault();
                        navigateWithScroll(
                          "/services/custom-software-development"
                        );
                      }}
                    >
                      Custom Software Development
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/services/cloud-services"
                      onClick={(e) => {
                        e.preventDefault();
                        navigateWithScroll("/services/cloud-services");
                      }}
                    >
                      Cloud Services
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/services/hire-developers"
                      onClick={(e) => {
                        e.preventDefault();
                        navigateWithScroll("/services/hire-developers");
                      }}
                    >
                      Hire Dedicated Team
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col-md-4">
                <ul className="list-links">
                  <li>
                    <Link
                      to="/about-us"
                      onClick={(e) => {
                        e.preventDefault();
                        navigateWithScroll("/about-us");
                      }}
                    >
                      <h4>About us</h4>
                    </Link>
                  </li>
                  <li>
                    <HashLink
                      scroll={scrollWithOffset}
                      className="dropdown-item"
                      to="/about-us#about"
                    >
                      About the company
                    </HashLink>
                  </li>
                  <li>
                    <HashLink
                      scroll={scrollWithOffset}
                      className="dropdown-item"
                      to="/about-us#visions"
                    >
                      Vision and Mission
                    </HashLink>
                  </li>
                  <li>
                    <HashLink
                      scroll={scrollWithOffset}
                      className="dropdown-item"
                      to="/about-us#leadership"
                    >
                      Leadership
                    </HashLink>
                  </li>
                  <li>
                    <HashLink
                      scroll={scrollWithOffset}
                      className="dropdown-item"
                      to="/about-us#confidentiality"
                    >
                      Confidentiality
                    </HashLink>
                  </li>
                </ul>
              </div>
              <div className="col-md-4">
                <ul className="list-links mt-5">
                  <li>
                    <Link
                      to="/services/hire-developers"
                      onClick={(e) => {
                        e.preventDefault();
                        navigateWithScroll("/services/hire-developers");
                      }}
                    >
                      <h4>Hire Developers</h4>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/hire-developers/hire-mobile-app-developer"
                      onClick={(e) => {
                        e.preventDefault();
                        navigateWithScroll(
                          "/hire-developers/hire-mobile-app-developer"
                        );
                      }}
                    >
                      Hire Mobile App Developer
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/hire-developers/hire-frontend-developer"
                      onClick={(e) => {
                        e.preventDefault();
                        navigateWithScroll(
                          "/hire-developers/hire-frontend-developer"
                        );
                      }}
                    >
                      Hire Frontend Developer
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/hire-developers/hire-backend-developer"
                      onClick={(e) => {
                        e.preventDefault();
                        navigateWithScroll(
                          "/hire-developers/hire-backend-developer"
                        );
                      }}
                    >
                      Hire Backend Developer
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/hire-developers/hire-stack-developers"
                      onClick={(e) => {
                        e.preventDefault();
                        navigateWithScroll(
                          "/hire-developers/hire-stack-developers"
                        );
                      }}
                    >
                      Hire Stack Developers
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col-md-4">
                <ul className="list-links mt-5">
                  <li>
                    <Link
                      to="/sitemap"
                      onClick={(e) => {
                        e.preventDefault();
                        navigateWithScroll("/sitemap");
                      }}
                    >
                      <h4>Other Links</h4>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/privacy-policy"
                      onClick={(e) => {
                        e.preventDefault();
                        navigateWithScroll("/privacy-policy");
                      }}
                    >
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/sitemap"
                      onClick={(e) => {
                        e.preventDefault();
                        navigateWithScroll("/sitemap");
                      }}
                    >
                      Sitemap
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/terms-and-conditions"
                      onClick={(e) => {
                        e.preventDefault();
                        navigateWithScroll("/terms-and-conditions");
                      }}
                    >
                      Terms and Conditions
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
