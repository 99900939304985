import React from "react";
import EnterpriseSolution from "../../../sections/Services/EnterpriseSolution";
import Breadcrumbs from "../../../components/Breadcrumbs";

export default function UiUxDesign() {
  return (
    <>
      <div id="content" className="site-content">
        <Breadcrumbs
          title="UI/UX Design"
          paths={[
            // { name: "Home", url: "/" },
            { name: "Services", url: "/services" },
            { name: "UI/UX Design" },
          ]}
        />
        <section className="aboutus-section">
          <div className="container wow fadeInUp">
            <div className="row mt-5">
              <div className="col-md-10 mx-auto mt-3">
                <div className="section-title text-center">
                  <h2>The perfect match for product design</h2>
                  <div className="dividebar"></div>
                  <p className="mt-3">
                    We are the top UI/UX design company in the market you can
                    fully count on, since we are constantly working to provide
                    creative UI/UX design services that can easily connect
                    businesses with their end-users. We focus more on the user’s
                    expectations and give a human touch to what we do. This
                    enables us to create stunning exquisite designs that
                    fascinate our customers and their customers.
                  </p>
                </div>
              </div>
            </div>
            <div className="row mt-5 pt-3">
              <div className="col wow fadeInUp" data-wow-delay="0.3s">
                <div className="uititle">
                  <h3>User Experience Design</h3>
                  <p>
                    Our expert designers create digital experiences that are
                    clear, purposeful and visually enjoyable for the end-users.
                    We use best techniques and practices which are suitable for
                    our client’s business goals.
                  </p>
                </div>
              </div>
              <div className="col wow fadeInUp" data-wow-delay="0.5s">
                <div className="ui_ux_img">
                  <img
                    alt="UI/UX"
                    src="../../wp-content/themes/wp-bootstrap-4/assets/images/ui-ux.png"
                  />
                </div>
              </div>
              <div className="col wow fadeInUp" data-wow-delay="0.7s">
                <div className="uititle">
                  <h3>User Interface Design</h3>
                  <p>
                    We offer user-friendly design services creating the
                    top-notch mobile interface experience to the target
                    audience. Our highly talented team creates UI/UX designs to
                    engage your customers.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="uistep-section">
          <div className="container">
            <div className="row mt-5">
              <div className="col-md-10 mx-auto">
                <div className="section-title text-center">
                  <h3>
                    Our User Experience Design process has proven itself time
                    and time again. We take the following steps for every design
                    we produce: Testing
                  </h3>
                </div>
              </div>
            </div>
            <div className="designsteps mt-5 wow fadeInUp">
              <div className="row">
                <div className="col-md-6">
                  <div className="stepimg">
                    <img
                      src="../../wp-content/themes/wp-bootstrap-4/assets/images/ui-1.png"
                      alt="Enterprise Solution"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="dec-texts">
                    <h2>1. Research</h2>
                    <ul>
                      <li>Discover the purpose of the product</li>
                      <li>Discover who the target audiences are</li>
                      <li>Discover what their expectations are</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="designsteps  wow fadeInUp">
              <div className="row">
                <div className="col-md-6">
                  <div className="dec-texts">
                    <h2>2. Storyboarding</h2>
                    <ul>
                      <li>Define use cases</li>
                      <li>Build storyboards for each use case</li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="stepimg">
                    <img
                      src="../../wp-content/themes/wp-bootstrap-4/assets/images/ui-2.png"
                      alt="Enterprise Solution"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="designsteps  wow fadeInUp">
              <div className="row">
                <div className="col-md-6">
                  <div className="stepimg">
                    <img
                      src="../../wp-content/themes/wp-bootstrap-4/assets/images/ui-3.png"
                      alt="Enterprise Solution"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="dec-texts">
                    <h2>3. Sketching</h2>
                    <ul>
                      <li>Make pencil sketches of various screen defaults</li>
                      <li>
                        Make pencil sketches of various screens under different
                        scenarios
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="designsteps wow fadeInUp">
              <div className="row">
                <div className="col-md-6">
                  <div className="dec-texts">
                    <h2>4. Wireframes</h2>
                    <ul>
                      <li>
                        Design without styling (colors/fonts/images infographics
                        )
                      </li>
                      <li>Enact CTA placement strategy</li>
                      <li>Craft call outs for on click actions</li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="stepimg">
                    <img
                      src="../../wp-content/themes/wp-bootstrap-4/assets/images/ui-4.png"
                      alt="Enterprise Solution"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="designsteps wow fadeInUp">
              <div className="row">
                <div className="col-md-6">
                  <div className="stepimg">
                    <img
                      src="../../wp-content/themes/wp-bootstrap-4/assets/images/ui-5.png"
                      alt="Enterprise Solution"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="dec-texts">
                    <h2>5. Visual Design</h2>
                    <ul>
                      <li>Actual Design output</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <EnterpriseSolution />
      </div>
    </>
  );
}
