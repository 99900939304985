import React from "react";
import JobDescription from "./JobDescription";
function DotNetDeveloper() {
  return (
    <div className="container">
      <div className="row pt-5 pt-lg-0 pb-5 my-5">
        <div className="col-md-12 wp-bp-content-width">
          <JobDescription
            title=".NET App Development"
            skills={[
              ".NET",
              "C#",
              "ASP.NET",
              "MVC",
              "SQL Server",
              "RESTful APIs",
              "HTML",
              "CSS",
              "JavaScript",
            ]}
            responsibilities={[
              "Design, develop, and maintain robust and scalable .NET applications.",
              "Collaborate with cross-functional teams to define, design, and ship new features.",
              "Create and consume RESTful APIs and web services.",
              "Write clean, maintainable, and well-documented code.",
              "Troubleshoot and debug applications to optimize performance and reliability.",
              "Participate in code reviews to ensure coding standards and best practices.",
              "Stay updated with the latest industry trends and technologies in .NET development.",
              "Work in an Agile environment, participating in daily stand-ups and sprint planning.",
            ]}
            requiredSkills={[
              "2+ years of experience in .NET development.",
              "Strong proficiency in C# and ASP.NET MVC framework.",
              "Experience with SQL Server and database design principles.",
              "Familiarity with front-end technologies like HTML, CSS, and JavaScript.",
              "Experience in building and consuming RESTful APIs.",
              "Knowledge of version control systems, especially Git.",
              "Strong analytical and problem-solving skills.",
              "Excellent communication and teamwork abilities.",
            ]}
            category="Development"
            jobType="Full Time"
            location="Surat"
            image="images/default-image.jpg"
          />
        </div>
      </div>
    </div>
  );
}

export default DotNetDeveloper;