import React from "react";
import { Link } from "react-router-dom";
import useNavigateWithScroll from "../../Hooks/useNavigateWithScroll";
export default function Footer() {
  const navigateWithScroll = useNavigateWithScroll();
  return (
    <>
      <footer className="site-footer bg-white text-white">
        <section className="footer-top">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-2">
                <div className="sitelogo">
                  <Link
                    to="/"
                    onClick={(e) => {
                      e.preventDefault();
                      navigateWithScroll("/");
                    }}
                  >
                    <img
                      src="/wp-content/themes/wp-bootstrap-4/assets/images/3dotttbg.png"
                      alt="3dottt logo in white"
                    />
                  </Link>
                </div>
              </div>
              <div className="col-lg-10 ">
                <div className="footer-actions ">
                  <a href="mailto:info@3dottt.com" className="mr-4">
                    <div className="d-flex align-items-center">
                      <div className="mr-3">
                        <img
                          src="/wp-content/themes/wp-bootstrap-4/assets/images/icons/icon-mail.svg"
                          alt="mail"
                        />
                      </div>
                      <div>
                        <span className="d-block text-muted">Mail us</span>
                        info@3dottt.com
                      </div>
                    </div>
                  </a>
                  <a href="tel:+918866980485">
                    <div className="d-flex align-items-center">
                      <div className="mr-3">
                        <img
                          src="/wp-content/themes/wp-bootstrap-4/assets/images/icons/icon-phone.svg"
                          alt="mail"
                        />
                      </div>
                      <div>
                        <span className="d-block text-muted">Call us</span>
                        +91 7016647130
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="widgets pl-0">
                  <h4 className="widgettitle">About us</h4>
                  <p>
                    We are 3Dottt Technologies. We build Mobile Apps, Web Apps
                    And Cloud Apps, that perform!
                  </p>
                  <p>Already have an app idea?</p>
                  <Link
                    to="/contact-us"
                    className="btn btn-round btn-primary"
                    onClick={(e) => {
                      e.preventDefault();
                      navigateWithScroll("/contact-us");
                    }}
                  >
                    Let's Talk
                    <img
                      className="ml-2"
                      src="/wp-content/themes/wp-bootstrap-4/assets/images/icons/arrow-right.svg"
                      alt="right"
                    />
                  </Link>
                </div>
              </div>
              <div className="col-md-6">
                <div className="widgets">
                  <h4 className="widgettitle">SERVICES</h4>
                  <div className="row">
                    <div className="col-md-6">
                      <ul className="quicklinks">
                        <li>
                          <Link
                            to="/services/web-development"
                            onClick={(e) => {
                              e.preventDefault();
                              navigateWithScroll("/services/web-development");
                            }}
                          >
                            Web Development
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/services/ui-ux-design"
                            onClick={(e) => {
                              e.preventDefault();
                              navigateWithScroll("/services/ui-ux-design");
                            }}
                          >
                            UI/UX-design
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/services/cloud-services"
                            onClick={(e) => {
                              e.preventDefault();
                              navigateWithScroll("/services/cloud-services");
                            }}
                          >
                            Cloud Services
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/services/custom-software-development"
                            onClick={(e) => {
                              e.preventDefault();
                              navigateWithScroll(
                                "/services/custom-software-development"
                              );
                            }}
                          >
                            Custom Software Development
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="col-md-6">
                      <ul className="quicklinks">
                        <li>
                          <Link
                            to="/services/mobile-app-development"
                            onClick={(e) => {
                              e.preventDefault();
                              navigateWithScroll(
                                "/services/mobile-app-development"
                              );
                            }}
                          >
                            Mobile app development
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/services/ecommerce-development"
                            onClick={(e) => {
                              e.preventDefault();
                              navigateWithScroll(
                                "/services/ecommerce-development"
                              );
                            }}
                          >
                            E-Commerce Development
                          </Link>
                        </li>

                        <li>
                          <Link
                            to="/services/product-engineering"
                            onClick={(e) => {
                              e.preventDefault();
                              navigateWithScroll(
                                "/services/product-engineering"
                              );
                            }}
                          >
                            Product Engineering
                          </Link>
                        </li>
                        {/* <li>
                          <Link to="services/business-intelligence">
                            Business Intelligence
                          </Link> 
                        </li> */}
                        <li>
                          <Link
                            to="/services/hire-developers"
                            onClick={(e) => {
                              e.preventDefault();
                              navigateWithScroll("/services/hire-developers");
                            }}
                          >
                            Hire Dedicated Developer
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="bottom-footer">
                  <div className="row align-items-center">
                    <div className="col-md-8">
                      <div className="d-flex flex-wrap bottminfo">
                        <div>© 2024 All Rights Reserved</div>
                        <div className="sepratr">|</div>
                        <Link
                          className="flink"
                          to="/privacy-policy"
                          onClick={(e) => {
                            e.preventDefault();
                            navigateWithScroll("/privacy-policy");
                          }}
                        >
                          Privacy Policy
                        </Link>
                        <div className="sepratr">|</div>
                        <Link
                          className="flink"
                          to="/sitemap"
                          onClick={(e) => {
                            e.preventDefault();
                            navigateWithScroll("/sitemap");
                          }}
                        >
                          Sitemap
                        </Link>
                        <div className="sepratr">|</div>
                        <Link
                          className="flink"
                          to="/terms-and-conditions"
                          onClick={(e) => {
                            e.preventDefault();
                            navigateWithScroll("/terms-and-conditions");
                          }}
                        >
                          Terms and Conditions
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </footer>
    </>
  );
}
