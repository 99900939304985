import React from "react";
import "./ReactDev.css";
import JobApplicationForm from "./JobApplicationForm";

function JobDescription({ title, skills, responsibilities, requiredSkills, category, jobType, location, image }) {
  return (
    <div className="container">
      <div className="row pt-5 pt-lg-0 pb-5 my-5">
        <div className="col-md-12 wp-bp-content-width">
          <div id="primary" className="content-area">
            <main id="main" className="site-main">
              <div className="wow slideInUp">
                <article>
                  <div className="blog_body">
                    <header className="entry-header1 pl-0">
                      <h1 className="entry-title card-title h2">{title}</h1>
                    </header>
                    {/* <div className="blog_thumbnails">
                      <img src={image || "images/default-image.jpg"} alt={title} />
                      <div className="post-thumbnail"></div>
                    </div> */}
                    <div className="entry-content"> 
                      <div className="d-lg-flex">
                        <div className="awsm-job-content">
                          <div className="awsm-job-entry-content entry-content mt-3">
                            <p>
                              <strong>Job Title</strong>: {title}
                            </p>
                            <p>
                              <strong>Technical Skills</strong>: {skills.join(", ")}
                            </p>
                            <p>
                              <strong>Role and Responsibilities</strong>
                            </p>
                            <ul>
                              {responsibilities.map((item, index) => (
                                <li key={index}>{item}</li>
                              ))}
                            </ul>
                            <p>
                              <strong>Required Skills</strong>
                            </p>
                            <ul>
                              {requiredSkills.map((item, index) => (
                                <li key={index}>{item}</li>
                              ))}
                            </ul>
                          </div>
                          <div className="awsm-job-specifications-container awsm_job_spec_below_content">
                            <div className="awsm-job-specifications-row">
                              <div className="awsm-job-specification-wrapper">
                                <div className="awsm-job-specification-item awsm-job-specification-job-category">
                                  <span className="awsm-job-specification-label">
                                    <strong>Job Category: </strong>
                                  </span>
                                  <span className="awsm-job-specification-term">{category}</span>
                                </div>
                                <div className="awsm-job-specification-item awsm-job-specification-job-type">
                                  <span className="awsm-job-specification-label">
                                    <strong>Job Type: </strong>
                                  </span>
                                  <span className="awsm-job-specification-term">{jobType}</span>
                                </div>
                                <div className="awsm-job-specification-item awsm-job-specification-job-location">
                                  <span className="awsm-job-specification-label">
                                    <strong>Job Location: </strong>
                                  </span>
                                  <span className="awsm-job-specification-term">{location}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <JobApplicationForm role={title} />
                      </div>
                    </div>
                  </div>
                </article>
              </div>
            </main>
          </div>
        </div>
      </div>
    </div>
  );
}

export default JobDescription;
