import { useNavigate } from 'react-router-dom';
export default function useNavigateWithScroll() {
    const navigate = useNavigate();
    const navigateWithScroll = (path) => {
        // Scroll to top
        window.scrollTo(0, 0);
        // Delay navigation to let the scroll animation complete
        setTimeout(() => {
            navigate(path);
        }, 580); // Adjust the delay (in milliseconds) as needed
    };
    return navigateWithScroll;
}
