// main code
import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import useNavigateWithScroll from "../../Hooks/useNavigateWithScroll";
// import flutterflowImg from "./assets/images/flutterflow.jpg";
export default function Navbar() {
  const navigateWithScroll = useNavigateWithScroll();
  const scrollWithOffset = (el) => {
    const yOffset = -200; // Adjust this value to the height of your sticky navbar
    const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: "smooth" });
  };

  const [toggleShow, setToggleShow] = useState(false);
  const [activeMenu, setActiveMenu] = useState(null);
  const menuRef = useRef(null);
  const handleToggle = () => {
    setToggleShow(!toggleShow);
  };
  const handleSubMenuToggle = (menuId) => {
    setActiveMenu(activeMenu === menuId ? "" : menuId);
  };
  useEffect(() => {
    if (menuRef.current) {
      const menuElement = menuRef.current;
      menuElement.style.height = toggleShow
        ? `${menuElement.scrollHeight}px`
        : "0px";
    }
  }, [toggleShow]);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const location = useLocation();
  // Close dropdown when navigating to a new page
  useEffect(() => {
    setIsDropdownOpen(false);
  }, [location]);
  // const handleDropdownToggle = () => {
  //   if (!isDropdownOpen) {
  //     setIsDropdownOpen((prev) => !prev);
  //   }
  // };
  const handleLinkClick = () => {
    // window.scrollTo(0, 0);
    // setTimeout(() => {
    setIsDropdownOpen(false);
    // }, 1000);
  };

  const getDropdownStyle = (menuId) => ({
    maxHeight: activeMenu === menuId ? "500px" : "0",
    opacity: activeMenu === menuId ? "1" : "0",
    overflow: "hidden",
    transition: "max-height 0.6s ease-in-out, opacity 0.8s ease-in-out",
    willChange: "max-height, opacity",
  });

  return (
    <>
      <header id="masthead" className="site-header">
        <nav
          id="site-navigation"
          className="main-navigation navbar navbar-expand-lg"
        >
          <div className="container">
            <div className="site-logo mt-1">
              <Link to="/" className="custom-logo-link" rel="home">
                <img
                  src="/wp-content/themes/wp-bootstrap-4/assets/images/3dotttbg.png"
                  className="custom-logo"
                  alt="3dottt Enterprise Pvt. Ltd"
                />
              </Link>
              <Link
                className="darklogo"
                to="/"
                onClick={(e) => {
                  e.preventDefault();
                  navigateWithScroll("/");
                }}
              >
                <img
                  src="/wp-content/themes/wp-bootstrap-4/assets/images/Darklogo.png"
                  alt="3dottt logo"
                />
              </Link>
            </div>

            <div className="navigation-menu">
              <ul className="navbar-nav ml-auto">
                <li className="dropdown">
                  <Link
                    // scroll={scrollWithOffset}
                    className="nav-menu dropdown-toggle"
                    to="/about-us"
                    onClick={(e) => {
                      e.preventDefault();
                      navigateWithScroll("/about-us");
                    }}
                  >
                    About Us
                  </Link>
                  <div className="dropdown-menu">
                    <HashLink
                      scroll={scrollWithOffset}
                      className="dropdown-item"
                      to="/about-us#about"
                    >
                      About the company
                    </HashLink>
                    <HashLink
                      scroll={scrollWithOffset}
                      className="dropdown-item"
                      to="/about-us#visions"
                    >
                      Vision and Mission
                    </HashLink>
                    <HashLink
                      scroll={scrollWithOffset}
                      className="dropdown-item"
                      to="/about-us#leadership"
                    >
                      Leadership
                    </HashLink>
                    <HashLink
                      scroll={scrollWithOffset}
                      className="dropdown-item"
                      to="/about-us#confidentiality"
                    >
                      Confidentiality
                    </HashLink>
                  </div>
                </li>

                {/* services */}
                <li
                  className={`dropdown fullmenu ${
                    isDropdownOpen ? "show" : ""
                  }`}
                  onMouseEnter={() => setIsDropdownOpen(true)}
                >
                  <Link
                    className="nav-menu dropdown-toggle"
                    to="/services"
                    // onClick={(e) => handleDropdownToggle(e)}
                    onClick={(e) => {
                      e.preventDefault();
                      handleLinkClick();
                      navigateWithScroll("/services");
                    }}
                  >
                    Services
                  </Link>
                  <div
                    className={`dropdown-menu bigmenu dropdown-menu-center ${
                      isDropdownOpen ? "show" : ""
                    }`}
                    style={{ display: isDropdownOpen ? "block" : "none" }} // Hide dropdown when isDropdownOpen is false
                  >
                    <div className="megamenu">
                      <div className="row">
                        <div className="col-md-3">
                          <Link
                            to="/services"
                            onClick={(e) => {
                              e.preventDefault();
                              handleLinkClick();
                              navigateWithScroll("/services");
                            }}
                          >
                            <h5 className="menu-title">Services</h5>
                          </Link>
                          <ul>
                            <li>
                              <Link
                                to="/services/web-development"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleLinkClick();
                                  navigateWithScroll(
                                    "/services/web-development"
                                  );
                                }}
                              >
                                Web Development
                              </Link>
                            </li>

                            <li>
                              <Link
                                to="/services/product-engineering"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleLinkClick();
                                  navigateWithScroll(
                                    "/services/product-engineering"
                                  );
                                }}
                              >
                                Product Engineering
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-md-3">
                          <Link
                            to="/services"
                            onClick={(e) => {
                              e.preventDefault();
                              handleLinkClick();
                              navigateWithScroll("/services");
                            }}
                          >
                            <h5 className="menu-title opacity-0">Services</h5>
                          </Link>
                          <ul>
                            <li>
                              <Link
                                to="/services/mobile-app-development"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleLinkClick();
                                  navigateWithScroll(
                                    "/services/mobile-app-development"
                                  );
                                }}
                              >
                                Mobile App Development
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/services/custom-software-development"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleLinkClick();
                                  navigateWithScroll(
                                    "/services/custom-software-development"
                                  );
                                }}
                              >
                                Custom Software Development
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-md-3">
                          <Link
                            to="/services"
                            onClick={(e) => {
                              e.preventDefault();
                              handleLinkClick();
                              navigateWithScroll("/services");
                            }}
                          >
                            <h5 className="menu-title opacity-0">Services</h5>
                          </Link>
                          <ul>
                            <li>
                              <Link
                                to="/services/ui-ux-design"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleLinkClick();
                                  navigateWithScroll("/services/ui-ux-design");
                                }}
                              >
                                UI/UX Design
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/services/cloud-services"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleLinkClick();
                                  navigateWithScroll(
                                    "/services/cloud-services"
                                  );
                                }}
                              >
                                Cloud Services
                              </Link>
                            </li>

                            {/* <li>
                              <Link
                                to="/services/business-intelligence"
                                onClick={handleLinkClick}
                              >
                                Business Intelligence
                              </Link>
                            </li> */}
                          </ul>
                        </div>
                        <div className="col-md-3">
                          <Link
                            to="/services"
                            onClick={(e) => {
                              e.preventDefault();
                              handleLinkClick();
                              navigateWithScroll("/services");
                            }}
                          >
                            <h5 className="menu-title opacity-0">Services</h5>
                          </Link>
                          <ul>
                            <li>
                              <Link
                                to="/services/ecommerce-development"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleLinkClick();
                                  navigateWithScroll(
                                    "/services/ecommerce-development"
                                  );
                                }}
                              >
                                E-commerce Development
                              </Link>
                            </li>

                            <li>
                              <Link
                                to="/services/hire-developers"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleLinkClick();
                                  navigateWithScroll("/services/hire-developers");
                                }}
                              >
                                Hire Dedicated Team
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                {/*  Hire Developers */}
                <li
                  className={`dropdown fullmenu ${
                    isDropdownOpen ? "show" : ""
                  }`}
                  onMouseEnter={() => setIsDropdownOpen(true)}
                >
                  <Link
                    className="nav-menu dropdown-toggle"
                    to="/services/hire-developers"
                    // onClick={(e) => handleDropdownToggle(e)}
                    onClick={(e) => {
                      e.preventDefault();
                      handleLinkClick();
                      navigateWithScroll("/services/hire-developers");
                    }}
                  >
                    Hire Developers
                  </Link>

                  <div
                    className={`dropdown-menu bigmenu dropdown-menu-center ${
                      isDropdownOpen ? "show" : ""
                    }`}
                    style={{ display: isDropdownOpen ? "block" : "none" }} // Hide dropdown when isDropdownOpen is false
                  >
                    <div className="megamenu hiretab">
                      <div className="row">
                        <div className="col-md-3">
                          <Link
                            to="/hire-developers/hire-mobile-app-developer"
                            onClick={(e) => {
                              e.preventDefault();
                              handleLinkClick();
                              navigateWithScroll(
                                "/hire-developers/hire-mobile-app-developer"
                              );
                            }}
                          >
                            <h5 className="menu-title">
                              Hire Mobile App Developer
                            </h5>
                          </Link>
                          <ul>
                            <li>
                              <Link
                                to="/hire-developers/hire-mobile-app-developer"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleLinkClick();
                                  navigateWithScroll(
                                    "/hire-developers/hire-mobile-app-developer"
                                  );
                                }}
                              >
                                <div className="techmenuicon">
                                  <span className="techicon android"></span>
                                </div>
                                Android
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/hire-developers/hire-mobile-app-developer"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleLinkClick();
                                  navigateWithScroll(
                                    "/hire-developers/hire-mobile-app-developer"
                                  );
                                }}
                              >
                                <div className="techmenuicon">
                                  <span className="techicon iphone"></span>
                                </div>
                                IOS
                              </Link>
                            </li>

                            <li>
                              <Link
                                to="/hire-developers/hire-mobile-app-developer"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleLinkClick();
                                  navigateWithScroll(
                                    "/hire-developers/hire-mobile-app-developer"
                                  );
                                }}
                              >
                                <div className="techmenuicon">
                                  <span className="techicon gflutter"></span>
                                </div>
                                Flutter
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/hire-developers/hire-mobile-app-developer"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleLinkClick();
                                  navigateWithScroll(
                                    "/hire-developers/hire-mobile-app-developer"
                                  );
                                }}
                              >
                                <div className="techmenuicon">
                                  <span
                                    className="techicon flutterflow"
                                    style={{ borderRadius: "10px" }}
                                  ></span>
                                </div>
                                FlutterFlow
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/hire-developers/hire-mobile-app-developer"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleLinkClick();
                                  navigateWithScroll(
                                    "/hire-developers/hire-mobile-app-developer"
                                  );
                                }}
                              >
                                <div className="techmenuicon">
                                  <span className="techicon reactjs"></span>
                                </div>
                                React Native
                              </Link>
                            </li>

                            <li>
                              <Link
                                to="/hire-developers/hire-mobile-app-developer"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleLinkClick();
                                  navigateWithScroll(
                                    "/hire-developers/hire-mobile-app-developer"
                                  );
                                }}
                              >
                                <div className="techmenuicon">
                                  <span className="techicon uiux"></span>
                                </div>
                                Ionic
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-md-3">
                          <Link
                            to="/hire-developers/hire-frontend-developer"
                            onClick={(e) => {
                              e.preventDefault();
                              handleLinkClick();
                              navigateWithScroll(
                                "/hire-developers/hire-frontend-developer"
                              );
                            }}
                          >
                            <h5 className="menu-title">
                              Hire Frontend Developer
                            </h5>
                          </Link>
                          <ul>
                            <li>
                              <Link
                                to="/hire-developers/hire-frontend-developer"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleLinkClick();
                                  navigateWithScroll(
                                    "/hire-developers/hire-frontend-developer"
                                  );
                                }}
                              >
                                <div className="techmenuicon">
                                  <span className="techicon angularjs"></span>
                                </div>
                                AngularJS
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/hire-developers/hire-frontend-developer"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleLinkClick();
                                  navigateWithScroll(
                                    "/hire-developers/hire-frontend-developer"
                                  );
                                }}
                              >
                                <div className="techmenuicon">
                                  <span className="techicon reactjs"></span>
                                </div>
                                ReactJS
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/hire-developers/hire-frontend-developer"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleLinkClick();
                                  navigateWithScroll(
                                    "/hire-developers/hire-frontend-developer"
                                  );
                                }}
                              >
                                <div className="techmenuicon">
                                  <span className="techicon vuejs"></span>
                                </div>
                                Vuejs
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-md-3">
                          <Link
                            to="/hire-developers/hire-backend-developer"
                            onClick={(e) => {
                              e.preventDefault();
                              handleLinkClick();
                              navigateWithScroll(
                                "/hire-developers/hire-backend-developer"
                              );
                            }}
                          >
                            <h5 className="menu-title">
                              Hire Backend Developer
                            </h5>
                          </Link>
                          <ul>
                            <li>
                              <Link
                                to="/hire-developers/hire-backend-developer"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleLinkClick();
                                  navigateWithScroll(
                                    "/hire-developers/hire-backend-developer"
                                  );
                                }}
                              >
                                <div className="techmenuicon">
                                  <span className="techicon python"></span>
                                </div>
                                Python
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/hire-developers/hire-backend-developer"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleLinkClick();
                                  navigateWithScroll(
                                    "/hire-developers/hire-backend-developer"
                                  );
                                }}
                              >
                                <div className="techmenuicon">
                                  <span className="techicon java"></span>
                                </div>
                                Java
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/hire-developers/hire-backend-developer"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleLinkClick();
                                  navigateWithScroll(
                                    "/hire-developers/hire-backend-developer"
                                  );
                                }}
                              >
                                <div className="techmenuicon">
                                  <span className="techicon php"></span>
                                </div>
                                PHP
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/hire-developers/hire-backend-developer"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleLinkClick();
                                  navigateWithScroll(
                                    "/hire-developers/hire-backend-developer"
                                  );
                                }}
                              >
                                <div className="techmenuicon">
                                  <span className="techicon nodejs"></span>
                                </div>
                                NodeJS
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/hire-developers/hire-backend-developer"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleLinkClick();
                                  navigateWithScroll(
                                    "/hire-developers/hire-backend-developer"
                                  );
                                }}
                              >
                                <div className="techmenuicon">
                                  <span className="techicon netcore"></span>
                                </div>
                                .NET
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <div className="col-md-3">
                          <Link
                            to="/hire-developers/hire-stack-developers"
                            onClick={(e) => {
                              e.preventDefault();
                              handleLinkClick();
                              navigateWithScroll(
                                "/hire-developers/hire-stack-developers"
                              );
                            }}
                          >
                            <h5 className="menu-title">
                              Hire Stack Developers
                            </h5>
                          </Link>
                          <ul>
                            <li>
                              <Link
                                to="/hire-developers/hire-stack-developers"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleLinkClick();
                                  navigateWithScroll(
                                    "/hire-developers/hire-stack-developers"
                                  );
                                }}
                              >
                                <div className="techmenuicon">
                                  <span className="techicon nodejs"></span>
                                </div>
                                MEAN Stack Developer
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/hire-developers/hire-stack-developers"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleLinkClick();
                                  navigateWithScroll(
                                    "/hire-developers/hire-stack-developers"
                                  );
                                }}
                              >
                                <div className="techmenuicon">
                                  <span className="techicon nodejs"></span>
                                </div>
                                MERN Stack Developer
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/hire-developers/hire-stack-developers"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleLinkClick();
                                  navigateWithScroll(
                                    "/hire-developers/hire-stack-developers"
                                  );
                                }}
                              >
                                <div className="techmenuicon">
                                  <span className="techicon nodejs"></span>
                                </div>
                                Full Stack Developer
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>

                {/* Blog */}

                {/* Career */}
                <li>
                  <Link
                    className="nav-menu"
                    to="/career"
                    onClick={(e) => {
                      e.preventDefault();
                      navigateWithScroll("/career");
                    }}
                  >
                    Career
                  </Link>
                </li>

                {/* Contact */}
                <li>
                  <Link
                    className="nav-menu"
                    to="/contact-us"
                    onClick={(e) => {
                      e.preventDefault();
                      navigateWithScroll("/contact-us");
                    }}
                  >
                    Contact us
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        {/* <!-- mobile menu --> */}
        <nav className="navbar mob-navigations">
          <div className="mobileheader">
            <div className="site-logo">
              <Link
                to="/"
                onClick={(e) => {
                  e.preventDefault();
                  navigateWithScroll("/");
                }}
              >
                <img
                  src="/wp-content/themes/wp-bootstrap-4/assets/images/Darklogo.png"
                  alt="3dottt logo"
                />
              </Link>
            </div>
            <div id="menuToggle" className="menu-toogle" onClick={handleToggle}>
              <input type="checkbox" checked={toggleShow} onChange={() => {}} />
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
          <div className={`collpasemenu ${toggleShow ? "show" : ""}`}>
            <ul className="navbar-nav">
              <li>
                <Link
                  className="nav-menu"
                  to="/"
                  onClick={(e) => {
                    e.preventDefault();
                    handleToggle();
                    navigateWithScroll("/");
                  }}
                >
                  Home
                </Link>
              </li>

              {/* ABOUT US */}
              <li
                className={`submenu ${
                  activeMenu === "menucompany" ? "active" : ""
                }`}
              >
                <Link
                  className={`submenu-link ${
                    activeMenu === "menucompany" ? "nav-menu collapsed" : ""
                  }`}
                  to="#menucompany"
                  onClick={() => handleSubMenuToggle("menucompany")}
                >
                  About us<canvas className="arrow"></canvas>
                </Link>
                <div
                  style={getDropdownStyle("menucompany")} // Apply the inline styles here
                  id="menucompany"
                >
                  <ul className="sub-menus">
                    <li onClick={handleToggle}>
                      <HashLink
                        scroll={scrollWithOffset}
                        className="nav-menu"
                        to="/about-us#about"
                      >
                        About the company
                      </HashLink>
                    </li>
                    <li onClick={handleToggle}>
                      <HashLink
                        scroll={scrollWithOffset}
                        className="nav-menu"
                        to="/about-us#visions"
                      >
                        Vision and Mission
                      </HashLink>
                    </li>
                    <li onClick={handleToggle}>
                      <HashLink
                        scroll={scrollWithOffset}
                        className="nav-menu"
                        to="/about-us#leadership"
                      >
                        Leadership
                      </HashLink>
                    </li>
                    <li onClick={handleToggle}>
                      <HashLink
                        scroll={scrollWithOffset}
                        className="nav-menu"
                        to="/about-us#confidentiality"
                      >
                        Confidentiality
                      </HashLink>
                    </li>
                  </ul>
                </div>
              </li>

              {/* Services */}
              <li
                className={`submenu ${
                  activeMenu === "menuservices" ? "active" : ""
                }`}
              >
                <Link
                  className={`submenu-link ${
                    activeMenu === "menuservices" ? "nav-menu collapsed" : ""
                  }`}
                  to="#menuservices"
                  onClick={() => handleSubMenuToggle("menuservices")}
                >
                  Services<canvas className="arrow"></canvas>
                </Link>
                <div
                  style={getDropdownStyle("menuservices")} // Apply the inline styles here
                  id="menuservices"
                >
                  <ul className="sub-menus">
                    <li onClick={handleToggle}>
                      <Link
                        className="nav-menu"
                        to="/services/web-development"
                        onClick={(e) => {
                          e.preventDefault();
                          navigateWithScroll("/services/web-development");
                        }}
                      >
                        Web Development
                      </Link>
                    </li>
                    <li onClick={handleToggle}>
                      <Link
                        className="nav-menu"
                        to="/services/mobile-app-development"
                        onClick={(e) => {
                          e.preventDefault();
                          navigateWithScroll(
                            "/services/mobile-app-development"
                          );
                        }}
                      >
                        Mobile App Development
                      </Link>
                    </li>
                    <li onClick={handleToggle}>
                      <Link
                        className="nav-menu"
                        to="/services/ui-ux-design"
                        onClick={(e) => {
                          e.preventDefault();
                          navigateWithScroll("/services/ui-ux-design");
                        }}
                      >
                        UI/UX Design
                      </Link>
                    </li>
                    <li onClick={handleToggle}>
                      <Link
                        className="nav-menu"
                        to="/services/ecommerce-development"
                        onClick={(e) => {
                          e.preventDefault();
                          navigateWithScroll("/services/ecommerce-development");
                        }}
                      >
                        E-commerce Development
                      </Link>
                    </li>

                    <li onClick={handleToggle}>
                      <Link
                        className="nav-menu"
                        to="/services/product-engineering"
                        onClick={(e) => {
                          e.preventDefault();
                          navigateWithScroll("/services/product-engineering");
                        }}
                      >
                        Product Engineering
                      </Link>
                    </li>
                    <li onClick={handleToggle}>
                      <Link
                        className="nav-menu"
                        to="/services/custom-software-development"
                        onClick={(e) => {
                          e.preventDefault();
                          navigateWithScroll(
                            "/services/custom-software-development"
                          );
                        }}
                      >
                        Custom Software Development
                      </Link>
                    </li>
                    <li onClick={handleToggle}>
                      <Link
                        className="nav-menu"
                        to="/services/cloud-services"
                        onClick={(e) => {
                          e.preventDefault();
                          navigateWithScroll("/services/cloud-services");
                        }}
                      >
                        Cloud Services
                      </Link>
                    </li>

                    <li onClick={handleToggle}>
                      <Link
                        className="nav-menu"
                        to="/services/hire-developers"
                        onClick={(e) => {
                          e.preventDefault();
                          navigateWithScroll("/services/hire-developers");
                        }}
                      >
                        Hire Dedicated Team
                      </Link>
                    </li>
                    <li onClick={handleToggle}>
                      <Link
                        className="nav-menu"
                        to="/services/business-intelligence"
                      >
                        Business Intelligence
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>

              {/* Hire Developers*/}
              <li
                className={`submenu ${activeMenu === "hireus" ? "active" : ""}`}
              >
                <Link
                  className={`submenu-link ${
                    activeMenu === "hireus" ? "nav-menu collapsed" : ""
                  }`}
                  to="#hireus"
                  onClick={() => handleSubMenuToggle("hireus")}
                >
                  Hire Developers<canvas className="arrow"></canvas>
                </Link>
                <div
                  style={getDropdownStyle("hireus")} // Apply the inline styles here
                  id="hireus"
                >
                  <ul className="sub-menus">
                    <li onClick={handleToggle}>
                      <Link
                        className="nav-menu"
                        to="/hire-developers/hire-mobile-app-developer"
                        onClick={(e) => {
                          e.preventDefault();
                          navigateWithScroll(
                            "/hire-developers/hire-mobile-app-developer"
                          );
                        }}
                      >
                        Hire Mobile App Developer
                      </Link>
                    </li>
                    <li onClick={handleToggle}>
                      <Link
                        className="nav-menu"
                        to="/hire-developers/hire-frontend-developer"
                        onClick={(e) => {
                          e.preventDefault();
                          navigateWithScroll(
                            "/hire-developers/hire-frontend-developer"
                          );
                        }}
                      >
                        Hire Frontend Developer
                      </Link>
                    </li>
                    <li onClick={handleToggle}>
                      <Link
                        className="nav-menu"
                        to="/hire-developers/hire-backend-developer"
                        onClick={(e) => {
                          e.preventDefault();
                          navigateWithScroll(
                            "/hire-developers/hire-backend-developer"
                          );
                        }}
                      >
                        Hire Backend Developer
                      </Link>
                    </li>
                    <li onClick={handleToggle}>
                      <Link
                        className="nav-menu"
                        to="/hire-developers/hire-stack-developers"
                        onClick={(e) => {
                          e.preventDefault();
                          navigateWithScroll(
                            "/hire-developers/hire-stack-developers"
                          );
                        }}
                      >
                        Hire Stack Developer
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>

              <li onClick={handleToggle}>
                <Link
                  className="nav-menu"
                  to="/career"
                  onClick={(e) => {
                    e.preventDefault();
                    navigateWithScroll("/career");
                  }}
                >
                  Career
                </Link>
              </li>

              {/* CONTACT */}
              <li onClick={handleToggle}>
                <Link
                  className="nav-menu"
                  to="/contact-us"
                  onClick={(e) => {
                    e.preventDefault();
                    navigateWithScroll("/contact-us");
                  }}
                >
                  Contact us
                </Link>
              </li>
            </ul>

            <div className="footer-actions mt-5">
              <Link to="mailto:info@3dottt.com">
                <div className="d-flex align-items-center">
                  <div className="mr-3">
                    <img
                      src="/wp-content/themes/wp-bootstrap-4/assets/images/icons/icon-mail.svg"
                      alt="mail"
                    />
                  </div>
                  <div>
                    <span className="d-block text-muted">Mail us</span>
                    info@3dottt.com
                  </div>
                </div>
              </Link>
              <Link to="tel:+918866980485">
                <div className="d-flex align-items-center">
                  <div className="mr-3">
                    <img
                      src="/wp-content/themes/wp-bootstrap-4/assets/images/icons/icon-phone.svg"
                      alt="mail"
                    />
                  </div>
                  <div>
                    <span className="d-block text-muted">Call us</span>
                    +91 7016647130
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
}
