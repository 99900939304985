import React from "react";
import { Link } from "react-router-dom";
import useNavigateWithScroll from "../Hooks/useNavigateWithScroll";

export default function OurExpertise() {
  const navigateWithScroll = useNavigateWithScroll();

  const handleNavigate = (path) => (e) => {
    e.preventDefault();
    navigateWithScroll(path);
  };

  const services = [
    {
      path: "/services/web-development",
      icon: "web-dev.svg",
      title: "Web Development",
      description:
        "At 3Dottt, our mission is to produce intuitive,attractive, and functional websites that you and yourcustomers will love.",
      wowDelay: "0.2s",
    },
    {
      path: "/services/mobile-app-development",
      icon: "app-dev.svg",
      title: "Mobile App Development",
      description:
        " We believe building an app needs amalgamation of passion,dedication, and expertise, for better results.",
      wowDelay: "0.4s",
    },
    {
      path: "/services/ui-ux-design",
      icon: "ux-ui.svg",
      title: "UI/UX Design",
      wowDelay: "0.6s",
      description:
        " At 3Dottt, our mission is to produce intuitive, attractive and functional websites that you and your customers will love.",
    },
    {
      path: "/services/ecommerce-development",
      icon: "ecommerce-site.svg",

      wowDelay: "0.2s",
      title: "E-commerce Development",
      description:
        " At 3Dottt, our mission is to produce intuitive, attractive and functional websites that you and your customers will love.",
    },
    {
      path: "/services/cloud-services",
      icon: "cloud.svg",
      title: "Cloud Services",
      wowDelay: "0.4s",
      description:
        "Find professional 3Dottt Technologies and get assistance to solve all your Google Cloud and Amazon Web Services problems.",
    },
    {
      path: "/services/product-engineering",
      icon: "product.svg",
      title: "Product Engineering",
      wowDelay: "0.6s",
      description:
        "Connect with our IoT experts that transform your idea into reality and create a big change!",
    },
    {
      path: "/services/business-intelligence",
      icon: "business-inte.svg",
      title: "Business Intelligence",
      wowDelay: "0.2s",
      description:
        "Optimize your reporting and enhance your data visualization with our Business Intelligence solutions.",
    },
    {
      path: "/services/custom-software-development",
      icon: "web-dev.svg",
      title: "Custom Software Development",
      wowDelay: "0.4s",
      description:
        "At 3Dottt, our mission is to produce intuitive, attractive and functional websites that you and your customers will love.",
    },
    {
      path: "/services/hire-developers",
      icon: "dedicate-dev.svg",
      title: "Hire Dedicated Team",
      wowDelay: "0.6s",
      description:
        "At 3Dottt, our mission is to produce intuitive, attractive and functional websites that you and your customers will love.",
    },
  ];

  return (
    <section className="our-services pt-5 overflow-hidden">
      <div className="container">
        <div className="row mb-4 pb-3">
          <div className="col-md-12">
            <div
              className="section-title text-center wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <h2>Our Expertise</h2>
              <div className="dividebar"></div>
            </div>
          </div>
        </div>
        <div className="row align-items-stretch justify-content-center">
          {services.map((service, index) => (
            <div key={index} className="col-md-6 col-lg-4 d-flex">
              {/* <div className="service-card wow fadeInUp" data-wow-delay={service.wowDelay} >  */}
              <Link
                to={service.path}
                className="service-card wow fadeInUp"
                data-wow-delay={service.wowDelay}
                onClick={handleNavigate(service.path)}
              >
                <div className="service-icon">
                  <img
                    className="backbg"
                    src="wp-content/themes/wp-bootstrap-4/assets/images/icons/shape-icons.svg"
                    alt={`3Dottt ${service.title}`}
                  />
                  <img
                    className="mainicon"
                    src={`wp-content/themes/wp-bootstrap-4/assets/images/icons/${service.icon}`}
                    alt={service.title}
                  />
                </div>
                <h4>{service.title}</h4>
                <div className="servi-text">
                  <p className="mb-0">{service.description}</p>
                </div>
                <div className="morebtn">
                  <img
                    src="wp-content/themes/wp-bootstrap-4/assets/images/icons/plus.svg"
                    alt="3Dottt service info"
                  />
                </div>
              </Link>
              {/* </div> */}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
