import React from "react";
import JobDescription from "./JobDescription";

function AngularJSDeveloper() {
  return (
    <JobDescription
      title="AngularJS App Development"
      skills={[
        "AngularJS",
        "JavaScript",
        "HTML5",
        "CSS3",
        "RESTful APIs",
        "jQuery",
        "Bootstrap",
        "Git",
        "Unit Testing",
        "Web Services",
        "MVC Architecture",
      ]}
      responsibilities={[
        "Develop dynamic web applications using AngularJS and related technologies.",
        "Collaborate with UI/UX designers to implement modern and responsive web interfaces.",
        "Ensure the performance, quality, and responsiveness of applications",
        "Write reusable, testable, and efficient code following best practices.",
        "Troubleshoot and debug applications to optimize performance.",
        "Stay updated with the latest industry trends, tools, and technologies in web development.",
        "Participate in Agile ceremonies, including daily scrums and sprint planning.",
      ]}
      requiredSkills={[
        "2+ years of experience in web application development using AngularJS.",
        "Proficient in JavaScript, HTML5, and CSS3 with strong knowledge of AngularJS concepts.",
        "Experience with RESTful APIs and integrating third-party libraries.",
        "Familiarity with version control systems, particularly Git.",
        "Understanding of MVC architecture and front-end development best practices.",
        "Experience with testing frameworks and unit testing in AngularJS.",
        "Strong problem-solving skills and attention to detail.",
        "Good communication skills, both written and verbal.",
      ]}
      category="Development"
      jobType="Full Time"
      location="Surat"
      image="images/default-image.jpg"
    />
  );
}

export default AngularJSDeveloper;