import React from "react";
import JobDescription from "./JobDescription";
// import JobApplicationForm from "./JobApplicationForm";

function BusinessDevelopmentExecutive() {
  return (
    <div className="container">
      <div className="row pt-5 pt-lg-0 pb-5 my-5">
        <div className="col-md-12 wp-bp-content-width">
          <JobDescription
            title="Business Development Executive"
            skills={[
              "Lead Generation",
              "Market Research",
              "CRM Software",
              "Sales Strategies",
              "Negotiation",
              "Customer Relationship Management",
              "Communication",
            ]}
            responsibilities={[
              "Identify new business opportunities and potential clients through market research and networking.",
              "Generate leads and build strong relationships with prospects, converting them into customers.",
              "Develop and execute strategies for sales, partnerships, and customer growth.",
              "Prepare and present business proposals, ensuring follow-up and negotiations for successful closure.",
              "Collaborate with the marketing team to align strategies and leverage marketing tools for business development.",
              "Manage client relationships, ensuring high levels of customer satisfaction and repeat business.",
              "Participate in client meetings, product demos, and negotiations to close deals effectively.",
              "Meet and exceed sales targets and performance metrics on a monthly and quarterly basis.",
              "Stay updated with industry trends and competitor activities to refine business development strategies.",
            ]}
            requiredSkills={[
              "2+ years of experience in business development or sales.",
              "Proven ability to generate leads, negotiate, and close deals.",
              "Experience using CRM software (e.g., Salesforce, Zoho CRM) to manage client interactions and pipeline.",
              "Strong presentation, communication, and interpersonal skills.",
              "Ability to develop and maintain long-term client relationships.",
              "Experience in managing multiple tasks and projects while meeting deadlines.",
              "Knowledge of market research, lead generation techniques, and sales strategies.",
              "Strong organizational skills and attention to detail.",
              "Goal-oriented and self-motivated with a passion for sales.",
            ]}
            category="Business Development"
            jobType="Full Time"
            location="Surat"
            image="images/default-image.jpg"
          />
        </div>
      </div>
    </div>
  );
}

export default BusinessDevelopmentExecutive;
