import React from "react";
import JobDescription from "./JobDescription";
function NodeJSDeveloper() {
  return (
    <div className="container">
      <div className="row pt-5 pt-lg-0 pb-5 my-5">
        <div className="col-md-12 wp-bp-content-width">
          <JobDescription
            title="NodeJS Developer"
            skills={[
              "Node.js", "Express.js", "MongoDB", "RESTful APIs", "JavaScript", "Git", "Docker", "JWT"
            ]}
            responsibilities={[
              "Design, develop, and maintain server-side applications and services using Node.js and Express.js.",
              "Collaborate with front-end developers to integrate user-facing elements with server-side logic.",
              "Implement RESTful APIs and microservices architecture.",
              "Ensure high performance and responsiveness of applications.",
              "Write clean, maintainable, and well-documented code.",
              "Troubleshoot, debug, and optimize application performance.",
              "Stay up-to-date with emerging technologies and industry trends in server-side development.",
              "Participate in Agile methodologies, including daily stand-ups and sprint planning. ",
            ]}
            requiredSkills={[
              "2+ years of experience in server-side development using Node.js.",
              "Strong knowledge of Express.js framework for building web applications.",
              "Experience with MongoDB or other NoSQL databases.",
              "Proficiency in JavaScript (ES6+) and asynchronous programming.",
              "Familiarity with version control systems, particularly Git.",
              "Experience in building and consuming RESTful APIs.",
              "Strong problem-solving skills and attention to detail.",
              "Excellent communication and teamwork skills.",
            ]}
            category="Development"
            jobType="Full Time" 
            location="Surat"  
            image="images/default-image.jpg"
          />
        </div>
      </div>
    </div>
  );
}
export default NodeJSDeveloper;