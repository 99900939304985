import React from "react";
import StackDevelopmentTechnologies from "../../sections/StackDevelopmentTechnologies";
import Breadcrumbs from "../../components/Breadcrumbs";
import Services from "../../sections/Services/Services";
import ContactUs from "../../sections/HireDevelopers/ContactUs";
export default function HireStackDevelopers() {
  const fullStackDevelopersTechStackData = {
    title: "Our Top-grade Full-Stack Developers Tech Stack",
    description:
      "Get cost-effective solutions from our stack developers who are highly qualified, full-time, and in-house employees.",
    services: [
      {
        icon: "fa fa-code",
        title: "Hire MEAN Stack Developers",
        description:
          "Build future-ready web Apps with our Full Stack MEAN developers who are highly experienced in MongoDB, ExpressJS, Angular, and NodeJS.",
      },
      {
        icon: "fa fa-code",
        title: "Hire MERN Developers",
        description:
          "Hire MERN Stack developers to design monolithic, multitier, and microservice architecture of applications that performs well.",
      },
      {
        icon: "fa fa-code",
        title: "React + React Native + MongoDB",
        description:
          "Hire React experts who can code both React web Apps and React Native-based hybrid mobile Apps with on-demand scalability.",
      },
      {
        icon: "fa fa-code",
        title: "Android + Flutter",
        description:
          "We have Full Stack Android developers on board who can also work with Flutter for developing cross-platform mobile apps.",
      },
      {
        icon: "fa fa-code",
        title: "Java + JS (React/Angular) + Firebase",
        description:
          "Hire our Full Stack specialists who can work with core Java, J2EE, Spring, Angular, React & Firebase to get the desired output.",
      },
      {
        icon: "fa fa-code",
        title: ".NET + Angular",
        description:
          "Hire .NET developers who can design, develop, deploy, integrate and handle front-end development with Angular.",
      },
    ],
  };
  return (
    <>
      <div id="content" className="site-content">
        <Breadcrumbs
          title="Hire Stack Developers"
          paths={[
            { name: "Hire Developers", url: "/services/hire-developers" },
            { name: "Hire Stack Developers" },
          ]}
        />
        <section className="aboutus-section pb-4">
          <div className="container wow slideInUp">
            <div className="row">
              <div className="col-md-12 mt-3">
                <div className="section-title text-center">
                  <h2>
                    Hire Dedicated Full Stack Developers At Your Flexibility
                  </h2>
                  <div className="dividebar"></div>
                  <p className="mt-3">
                    For business-centric front-end and back-end development,
                    connect with our proficient Full stack developers.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="hire-section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-5 wow slideInLeft">
                <div className="hire-img">
                  <img
                    src="../../wp-content/themes/wp-bootstrap-4/assets/images/hired/full-stack.jpg"
                    alt="img"
                  />
                </div>
              </div>
              <div className="col-md-7 wow slideInRight">
                <div className="hire-info pl-4">
                  <h3>Stack Developer</h3>
                  <p>
                    Going post-to-pillar for a complete IT solution bothers
                    every entrepreneur. Hire full stack developers who know the
                    technology inside out and provide the best full stack
                    development services to enterprises.
                  </p>
                  <ul className="hirelist">
                    <li className="w-100">
                      <div className="techmenuicon">
                        <span className="techicon angularjs"></span>
                      </div>
                      Hire MEAN Stack Developer
                    </li>
                    <li className="w-100">
                      <div className="techmenuicon">
                        <span className="techicon reactjs"></span>
                      </div>
                      Hire MERN Stack Developer
                    </li>
                    <li className="w-100">
                      <div className="techmenuicon">
                        <span className="techicon nodejs"></span>
                      </div>
                      Hire Full Stack Developer
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="hire-section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12 text-center wow zoomIn">
                <h2>
                  Hire dedicated Stack Developers for advanced services
                  including web and mobile apps, web portals, SaaS solutions.
                  Also, our pocket-friendly prices attract many startups and
                  SMBs.
                </h2>
              </div>
              <div className="col-md-6 mt-4 wow zoomIn">
                <p className="h4 text-muted font-weight-normal l-height">
                  Our Full Stack front-end and back-end developers have 5+ years
                  of experience that helps them to write optimized code and
                  build a user-engaging, lead-converting, and appealing
                  application. And, we have a proven track record of delivering
                  projects on time by comprehending appropriate stages of
                  software development that involve PHP development, MEAN Stack
                  development, MERN Stack development, HTML/CSS, mobility, Full
                  Stack JavaScript technologies, middleware, back-end languages,
                  databases, and web storage.
                </p>
              </div>
              <div className="col-md-6 mt-4 wow zoomIn">
                <p className="h5 font-weight-normal l-height">
                  Our most trusted senior Full Stack engineers can build your
                  desired product 2x faster with the help of top programming
                  languages like Ruby on Rails, SQLite, React, LEMP - Linux,
                  Nginx, MySQL, Flutter, Apache, Angular, Node, PHP, etc. We
                  have assisted numerous businesses to raise their business
                  scales and boost revenue by offering robust end-to-end Full
                  Stack development services.
                </p>
              </div>
            </div>
          </div>
        </section>
        <StackDevelopmentTechnologies />
        <Services
          title={fullStackDevelopersTechStackData.title}
          description={fullStackDevelopersTechStackData.description}
          services={fullStackDevelopersTechStackData.services}
        />
        <section className="hire-section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12">
                <div className="section-title text-center wow fadeInUp">
                  <h2>Flexible Models for Hiring Stack Developers</h2>
                  <div className="dividebar"></div>
                  <p className="mt-3">
                    Flexible models to hire frontend programmers based on your
                    business requirements and budget. Get a dedicated team of
                    expert frontend developers to work exclusively on your
                    project.
                  </p>
                </div>
              </div>
              <div className="col-md-4 mt-4">
                <div className="service-card text-left wow slideInUp">
                  <div className="service-icon">
                    <img
                      className="backbg"
                      src="../../wp-content/themes/wp-bootstrap-4/assets/images/icons/shape-icons.svg"
                      alt="3Dottt software development"
                    />
                    <img
                      className="mainicon"
                      src="../../wp-content/themes/wp-bootstrap-4/assets/images/icons/managedhosting.svg"
                      alt="Mobile App Development"
                    />
                  </div>
                  <h4>
                    3Dottt Technologies <br /> Managed Team
                  </h4>
                  <div className="servi-text">
                    <p className="mb-0">
                      With our unique hire managed team services, we will take
                      care of all your project needs with a dedicated
                      development team & manager. We take full responsibility
                      for app development needs to complement your core business
                      goals.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mt-4">
                <div className="service-card text-left wow slideInUp">
                  <div className="service-icon">
                    <img
                      className="backbg"
                      src="../../wp-content/themes/wp-bootstrap-4/assets/images/icons/shape-icons.svg"
                      alt="3Dottt software development"
                    />
                    <img
                      className="mainicon"
                      src="../../wp-content/themes/wp-bootstrap-4/assets/images/icons/user-identification.svg"
                      alt="Mobile App Development"
                    />
                  </div>
                  <h4>
                    Client <br /> Managed Team
                  </h4>
                  <div className="servi-text">
                    <p className="mb-0">
                      Our team of experienced mobile app developers will work
                      with your in-house team or project managers. Our talent
                      pool will provide complete offshore development support,
                      ensuring high quality and cost-efficiency.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 mt-4">
                <div className="service-card text-left wow slideInUp">
                  <div className="service-icon">
                    <img
                      className="backbg"
                      src="../../wp-content/themes/wp-bootstrap-4/assets/images/icons/shape-icons.svg"
                      alt="3Dottt software development"
                    />
                    <img
                      className="mainicon"
                      src="../../wp-content/themes/wp-bootstrap-4/assets/images/icons/database.svg"
                      alt="Mobile App Development"
                    />
                  </div>
                  <h4>
                    Hybrid <br /> Model
                  </h4>
                  <div className="servi-text">
                    <p className="mb-0">
                      The hybrid model provides the benefits of different models
                      such as deploying your team on-site and the other section
                      works from offshore development centers. It effectively
                      combines the best of both hiring models to give you
                      agility and flexibility.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ContactUs />
      </div>
    </>
  );
}
