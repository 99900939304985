import React from "react";
import JobDescription from "./JobDescription";
function MeanStackDeveloper() {
  return (
    <div className="container">
      <div className="row pt-5 pt-lg-0 pb-5 my-5">
        <div className="col-md-12 wp-bp-content-width">
          <JobDescription
            title="MEAN Stack Developer"
            skills={[
             "MongoDB", "Express.js", "Angular", "Node.js", "JavaScript", "HTML", "CSS", "RESTful APIs"
            ]}
            responsibilities={[
              "Design, develop, and maintain applications using the MEAN stack (MongoDB, Express.js, Angular, Node.js).",
              "Collaborate with front-end developers to integrate user-facing elements with server-side logic.",
              "Build and consume RESTful APIs to connect front-end and back-end systems.",
              "Optimize applications for maximum speed and scalability.",
              "Write clean, maintainable code with proper documentation.",
              "Participate in code reviews to ensure coding standards and best practices.",
              "Stay updated with emerging technologies and trends in web development.",
              "Work in an Agile environment, participating in daily stand-ups and sprint planning.",
            ]}
            requiredSkills={[
              "2+ years of experience in web development using the MEAN stack.",
              "Strong proficiency in MongoDB, Express.js, Angular, and Node.js.",
              "Experience with front-end technologies such as HTML5, CSS3, and JavaScript.",
              "Familiarity with RESTful APIs and web services.",
              "Understanding of version control systems, particularly Git.",
              "Strong analytical and problem-solving skills.",
              "Excellent communication and teamwork abilities.",
            ]}
            category="Development"
            jobType="Full Time" 
            location="Surat"  
            image="images/default-image.jpg"
          />
        </div>
      </div>
    </div>
  );
}
export default MeanStackDeveloper;