import React from "react";
import JobDescription from "./JobDescription";
function ReactJSDeveloper () {
  return (
    <div className="container">
      <div className="row pt-5 pt-lg-0 pb-5 my-5">
        <div className="col-md-12 wp-bp-content-width">
          <JobDescription
            title="ReactJS Developer"
            skills={[
              "ReactJS", "JavaScript", "HTML5", "CSS3", "Redux", "RESTful APIs", "Git", "Webpack", "Jest", "Agile Methodologies"
            ]}
            responsibilities={[
"Develop user-friendly web applications using ReactJS and related technologies.",
"Collaborate with designers and back-end developers to create seamless user experiences.",
"Optimize components for maximum performance across a vast array of web-capable devices and browsers.",
"Write clean, maintainable, and reusable code while following best practices.",
"Conduct code reviews and provide constructive feedback to team members.",
"Implement state management solutions using Redux or similar libraries.",
"Participate in Agile ceremonies, including sprint planning, retrospectives, and daily stand-ups.",
"Stay up to date with the latest trends and technologies in front-end development.",
            ]}
            requiredSkills={[
              
"2+ years of experience in front-end development with ReactJS.",
"Proficient in JavaScript (ES6+), HTML5, and CSS3.",
"Experience with state management libraries like Redux.",
"Familiarity with RESTful APIs and integrating with back-end services.",
"Experience with version control systems, particularly Git.",
"Knowledge of modern front-end build pipelines and tools (e.g., Webpack).",
"Strong problem-solving skills and attention to detail.",
"Excellent communication skills and ability to work in a team environment.",
              
              
              
              
              
              
            ]}
            category="Development"
            jobType="Full Time" 
            location="Surat"  
            image="images/default-image.jpg"
          />
        </div>
      </div>
    </div>
  );
}
export default ReactJSDeveloper ;