import React from "react";
import { Link } from "react-router-dom";
import useNavigateWithScroll from "../../Hooks/useNavigateWithScroll";

export default function ContactUs() {
  const navigateWithScroll = useNavigateWithScroll();
  return (
    <>
      <section className="aboutus-section pt-0 wow fadeInUp">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="join-us mt-0">
                <div>
                  <span className="font-weight-normal">
                    Ready to start your dream project?
                    <h4>We have a TEAM to get you there.</h4>
                  </span>
                </div>
                <Link to="/contact-us" className="btn btn-primary btn-round" onClick={(e) => {e.preventDefault();navigateWithScroll('/contact-us');}}>
                  Contact Us
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
