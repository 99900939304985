import React from "react";
import JobDescription from "./JobDescription";
function JavaDeveloper() {
  return (
    <div className="container">
      <div className="row pt-5 pt-lg-0 pb-5 my-5">
        <div className="col-md-12 wp-bp-content-width">
          <JobDescription
            title="Java Developer"
            skills={[
             "Java", "Spring", "Hibernate", "RESTful APIs", "Microservices", "SQL", "Git", "Maven", "Docker"
            ]}
            responsibilities={[
              "Design, develop, and maintain high-performance, scalable Java applications.",
              "Collaborate with cross-functional teams to define, design, and ship new features.",
              "Write clean, efficient, and well-documented code following industry best practices.",
              "Develop and implement RESTful APIs and microservices.",
              "Troubleshoot and debug applications to optimize performance.",
              "Participate in code reviews and provide constructive feedback to team members.",
              "Stay up-to-date with emerging technologies and industry trends.",
              "Participate in Agile/Scrum methodologies, including daily stand-ups and sprint planning.",
              
            ]}
            requiredSkills={[
"2+ years of experience in Java development.",
"Strong knowledge of Java frameworks such as Spring and Hibernate.",
"Experience with RESTful APIs and microservices architecture.",
"Proficient in SQL and experience with relational databases.",
"Familiarity with version control systems, particularly Git.",
"Experience with build tools like Maven and containerization using Docker.",
"Strong problem-solving skills and attention to detail.",
"Excellent communication skills and ability to work collaboratively in a team environment.",
            ]}
            category="Development"
            jobType="Full Time" 
            location="Surat"  
            image="images/default-image.jpg"
          />
        </div>
      </div>
    </div>
  );
}
export default JavaDeveloper;