import React, { useState } from "react";
const StackDevelopmentTechnologies = () => {
  const [activeTab, setActiveTab] = useState("frontend");
  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };
  return (
    <section className="technologies-section">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="section-title text-center wow slideInUp">
              <h2>Stack Development Technologies</h2>
              <div className="dividebar"></div>
            </div>
          </div>
          <div className="col-md-8 mx-auto wow slideInUp">
            {/* Nav tabs */}
            <ul className="nav nav-pills">
              <li className="nav-item">
                <button
                  className={`nav-link ${
                    activeTab === "frontend" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("frontend")}
                >
                  Frontend
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link ${
                    activeTab === "backend" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("backend")}
                >
                  Backend
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link ${
                    activeTab === "database" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("database")}
                >
                  Databases
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link ${
                    activeTab === "clouds" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("clouds")}
                >
                  DevOps / Clouds
                </button>
              </li>
            </ul>
            {/* Tab panes */}
            <div className="tab-content">
              {activeTab === "frontend" && (
                <div className="tab-pane active" id="frontend">
                  <ul className="techlist">
                    <li>
                      <div className="tech-card">
                        <div className="techwrap">
                          <span className="techicon reactjs"></span>
                        </div>
                        <span>React.js</span>
                      </div>
                    </li>
                    <li>
                      <div className="tech-card">
                        <div className="techwrap">
                          <span className="techicon angularjs"></span>
                        </div>
                        <span>Angular</span>
                      </div>
                    </li>
                    <li>
                      <div className="tech-card">
                        <div className="techwrap">
                          <span className="techicon vuejs"></span>
                        </div>
                        <span>VueJs</span>
                      </div>
                    </li>
                    <li>
                      <div className="tech-card">
                        <div className="techwrap">
                          <span className="techicon knockout"></span>
                        </div>
                        <span>knockoutJs</span>
                      </div>
                    </li>
                  </ul>
                </div>
              )}
              {activeTab === "backend" && (
                <div className="tab-pane active" id="backend">
                  <ul className="techlist">
                    <li>
                      <div className="tech-card">
                        <div className="techwrap">
                          <span className="techicon nodejs"></span>
                        </div>
                        <span>Nodejs</span>
                      </div>
                    </li>
                    <li>
                      <div className="tech-card">
                        <div className="techwrap">
                          <span className="techicon php"></span>
                        </div>
                        <span>Php</span>
                      </div>
                    </li>
                    <li>
                      <div className="tech-card">
                        <div className="techwrap">
                          <span className="techicon java"></span>
                        </div>
                        <span>Java</span>
                      </div>
                    </li>
                    <li>
                      <div className="tech-card">
                        <div className="techwrap">
                          <span className="techicon python"></span>
                        </div>
                        <span>Python</span>
                      </div>
                    </li>
                    <li>
                      <div className="tech-card">
                        <div className="techwrap">
                          <span className="techicon aspnet"></span>
                        </div>
                        <span>asp.net</span>
                      </div>
                    </li>
                    <li>
                      <div className="tech-card">
                        <div className="techwrap">
                          <span className="techicon csharp"></span>
                        </div>
                        <span>C sharp</span>
                      </div>
                    </li>
                  </ul>
                </div>
              )}
              {activeTab === "database" && (
                <div className="tab-pane active" id="database">
                  <ul className="techlist">
                    <li>
                      <div className="tech-card">
                        <div className="techwrap">
                          <span className="techicon mongodb"></span>
                        </div>
                        <span>MongoDB</span>
                      </div>
                    </li>
                    <li>
                      <div className="tech-card">
                        <div className="techwrap">
                          <span className="techicon mysql"></span>
                        </div>
                        <span>MySQL</span>
                      </div>
                    </li>
                    <li>
                      <div className="tech-card">
                        <div className="techwrap">
                          <span className="techicon postgre"></span>
                        </div>
                        <span>PostgreSQL</span>
                      </div>
                    </li>
                    <li>
                      <div className="tech-card">
                        <div className="techwrap">
                          <span className="techicon sqlserver"></span>
                        </div>
                        <span>SQL Server</span>
                      </div>
                    </li>
                  </ul>
                </div>
              )}

              {activeTab === "clouds" && (
                <div className="tab-pane active" id="clouds">
                  <ul className="techlist">
                    <li>
                      <div className="tech-card">
                        <div className="techwrap">
                          <span className="techicon gcloud"></span>
                        </div>
                        <span>Google Cloud</span>
                      </div>
                    </li>
                    <li>
                      <div className="tech-card">
                        <div className="techwrap">
                          <span className="techicon aws"></span>
                        </div>
                        <span>AWS</span>
                      </div>
                    </li>
                    <li>
                      <div className="tech-card">
                        <div className="techwrap">
                          <span className="techicon azure"></span>
                        </div>
                        <span>Azure</span>
                      </div>
                    </li>
                    <li>
                      <div className="tech-card">
                        <div className="techwrap">
                          <span className="techicon doker"></span>
                        </div>
                        <span>Doker</span>
                      </div>
                    </li>
                    <li>
                      <div className="tech-card">
                        <div className="techwrap">
                          <span className="techicon jenkins"></span>
                        </div>
                        <span>Jenkins</span>
                      </div>
                    </li>
                    <li>
                      <div className="tech-card">
                        <div className="techwrap">
                          <span className="techicon kubernetes"></span>
                        </div>
                        <span>Kubernetes</span>
                      </div>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default StackDevelopmentTechnologies;
