import React from "react";
import JobDescription from "./JobDescription";
function PythonDeveloper() {
  return (
    <div className="container">
      <div className="row pt-5 pt-lg-0 pb-5 my-5">
        <div className="col-md-12 wp-bp-content-width">
          <JobDescription
            title="Python Developer"
            skills={[
              "Python", "Django", "Flask", "RESTful APIs", "Microservices", "PostgreSQL", "Git", "Docker", "AWS"
            ]}
            responsibilities={[
              "Design and develop scalable Python applications.",
              "Collaborate with cross-functional teams to define, design, and deploy new features.",
              "Write clean, maintainable, and efficient code following Python best practices.",
              "Develop RESTful APIs and microservices.",
              "Troubleshoot, debug, and optimize applications.",
              "Participate in code reviews and offer constructive feedback.",
              "Stay updated with Python technologies and frameworks.",
              "Work in an Agile/Scrum environment.",
            ]}
            requiredSkills={[
              "2+ years of experience in Python development.",
              "Strong knowledge of Python frameworks such as Django and Flask.",
              "Experience with RESTful APIs and microservices architecture.",
              "Proficiency in PostgreSQL and working with databases.",
              "Familiarity with version control (Git).",
              "Experience with Docker and cloud platforms like AWS.",
              "Strong problem-solving skills and a collaborative mindset.",
              "Excellent communication skills and ability to work in a team.",
            ]}
            category="Development"
            jobType="Full Time" 
            location="Surat"  
            image="images/default-image.jpg"
          />
        </div>
      </div>
    </div>
  );
}
export default PythonDeveloper;