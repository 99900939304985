import React from "react";
import JobDescription from "./JobDescription";
function IOSDeveloper() {
  return (
    <div className="container">
      <div className="row pt-5 pt-lg-0 pb-5 my-5">
        <div className="col-md-12 wp-bp-content-width">
          <JobDescription
            title="IOS Developer"
            skills={[
             "Swift", "Objective-C", "iOS SDK", "Xcode", "UIKit", "Core Data", "REST APIs", "Git, SQLite", "MVC/MVVM Architecture", "CocoaPods", "Auto Layout","JSON", "Firebase"
            ]}
            responsibilities={[
              "Design and build advanced applications for the iOS platform using Swift and Objective-C.",
              "Collaborate with cross-functional teams to define, design, and ship new features.",
              "Work with outside data sources and APIs.",
              "Ensure the performance, quality, and responsiveness of applications.",
              "Identify and fix bugs, and improve application performance.",
              "Continuously discover, evaluate, and implement new technologies to maximize development efficiency.",
              "Stay updated with the latest Apple guidelines and iOS app development practices.",
              "Adhere to Agile practices including daily scrums, sprints, and retrospective meetings.",
            ]}
            requiredSkills={[
              "2+ years of experience in iOS app development.",
              "Proficient in Swift and Objective-C with hands-on experience in Xcode.",
              "Experience with iOS frameworks such as Core Data, Core Animation, and Core Graphics.",
              "Knowledge of RESTful APIs to connect iOS applications to back-end services.",
              "Experience with offline storage, threading, and performance tuning.",
              "Familiarity with cloud message APIs, push notifications, and Firebase.",
              "Strong understanding of Apple’s design principles and interface guidelines.",
              "Strong problem-solving and debugging skills.",
              "Experience with automated testing frameworks and unit tests.",
            ]}
            category="Development"
            jobType="Full Time" 
            location="Surat"
            image="images/default-image.jpg"
          />
        </div>
      </div>
    </div>
  );
}
export default IOSDeveloper;