import React from "react";
import JobDescription from "./JobDescription";
function VueJSDeveloper () {
  return (
    <div className="container">
      <div className="row pt-5 pt-lg-0 pb-5 my-5">
        <div className="col-md-12 wp-bp-content-width">
          <JobDescription
            title="VueJS Developer"
            skills={[
              "Vue.js", "JavaScript", "HTML5", "CSS3", "Vuex", "RESTful APIs", "Axios", "Git", "Webpack", "Nuxt.js"


            ]}
            responsibilities={[
"Develop and maintain high-quality web applications using Vue.js and related technologies.",
"Collaborate with UX/UI designers to translate designs into interactive applications.",
"Optimize applications for maximum speed and scalability.",
"Implement state management solutions using Vuex.",
"Write clean, maintainable, and reusable code while adhering to best practices.",
"Conduct code reviews and provide feedback to peers to improve code quality.",
"Integrate with RESTful APIs and third-party services using Axios.",
"Stay updated with the latest trends and advancements in front-end development.",
"Participate in Agile methodologies, including sprint planning and daily stand-ups.",
            ]}
            requiredSkills={[
              
"2+ years of experience in front-end development using Vue.js.",
"Proficient in JavaScript (ES6+), HTML5, and CSS3.",
"Experience with Vuex for state management in Vue applications.",
"Familiarity with RESTful APIs and asynchronous request handling using Axios.",
"Strong understanding of web development best practices and responsive design.",
"Experience with version control systems, particularly Git.",
"Knowledge of modern front-end build tools (Webpack, Babel, etc.).",
"Strong problem-solving skills and attention to detail.",
"Excellent communication skills and ability to work collaboratively in a team.",
              
              
              
              
              
              
            ]}
            category="Development"
            jobType="Full Time" 
            location="Surat"  
            image="images/default-image.jpg"
          />
        </div>
      </div>
    </div>
  );
}
export default VueJSDeveloper ;