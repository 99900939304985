// src/components/Breadcrumbs.js
import React from "react";
import { Link } from "react-router-dom";

export default function Breadcrumbs({ title, paths }) {
  return (
    <section className="pagettile">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1 className="text-center text-white">{title}</h1>
            <ul className="bredcumbss">
              {paths.map((path, index) => (
                <li key={index}>
                  {path.url ? (
                    <Link to={path.url}>{path.name}</Link>
                  ) : (
                    path.name
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}
