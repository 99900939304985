import React from "react";

export default function Services({ title, description, services }) {
  return (
    <section className="aboutus-section bg-section heathcares">
      <div className="container">
        <div className="row ">
          <div className="col-md-12 mt-3">
            <div className="section-title text-center wow slideInUp">
              <h2>{title}</h2>
              <div className="dividebar"></div>
              <p className="mt-3">{description}</p>
            </div>
          </div>
        </div>
        <div className="row mt-5 align-items-center">
          {services.map((service, index) => (
            <div className="col-md-6" key={index}>
              <div className="media whychoosemedia wow slideInUp">
                <div className="mr-3">
                  <i className={service.icon}></i>
                </div>
                <div className="media-body">
                  <h5 className="mt-0">{service.title}</h5>
                  <p>{service.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
