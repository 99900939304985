import React from "react";
import JobDescription from "./JobDescription";
function IonicDeveloper() {
  return (
    <div className="container">
      <div className="row pt-5 pt-lg-0 pb-5 my-5">
        <div className="col-md-12 wp-bp-content-width">
          <JobDescription
            title="Ionic Developer"
            skills={[
              "Ionic Framework", "Angular", "TypeScript", "HTML5", "CSS3", "JavaScript", "REST APIs", "Git", "Cordova/Capacitor", "Firebase", "SQLite", "Hybrid Mobile App Development"
            ]}
            responsibilities={[
              "Design and build hybrid mobile applications using Ionic framework and Angular.",
              "Collaborate with cross-functional teams to define, design, and implement new features.",
              "Ensure the performance, quality, and responsiveness of applications across various devices and platforms.",
              "Work on bug fixing and improving application performance.",
              "Continuously discover, evaluate, and implement new technologies to maximize development efficiency.",
              "Stay updated with the latest industry trends and Ionic/Angular best practices.",
              "Ensure that hybrid applications can integrate seamlessly with native device features via plugins and libraries.",
              "Adhere to Agile practices including sprints, daily scrums, and retrospective meetings.",
              
            ]}
            requiredSkills={[
"2+ years of experience in Ionic framework and hybrid mobile app development.",
"Proficient in Ionic, Angular, and TypeScript with hands-on experience in Cordova or Capacitor.",
"Experience with HTML5, CSS3, and JavaScript for responsive app design.",
"Knowledge of RESTful APIs to connect mobile applications to back-end services.",
"Familiarity with version control systems like Git and mobile app deployment processes.",
"Strong understanding of cross-platform mobile app development (iOS and Android).",
"Experience working with Firebase and SQLite databases.",
"Strong problem-solving and debugging skills.",
"Experience with automated testing frameworks and unit tests.",
            ]}
            category="Development"
            jobType="Full Time" 
            location="Surat"
            image="images/default-image.jpg"
          />
        </div>
      </div>
    </div>
  );
}
export default IonicDeveloper;