import React from "react";
import EnterpriseSolution from "../../../sections/Services/EnterpriseSolution";
import Breadcrumbs from "../../../components/Breadcrumbs";
import Services from "../../../sections/Services/Services";
export default function MobileAppDevelopment() {
  const mobileAppDevelopmentExpertiseData = {
    title: "The Expertise of Our Mobile App Development Team",
    description:
      "Hire mobile applications developer from 3Dottt Technologies and get customer-oriented applications across multiple platforms. Our app engineering team has 5+ years of expertise in creating apps for different industries. Here are some expertise of our mobile app developers:",
    services: [
      {
        icon: "fa fa-mobile",
        title: "Custom Mobile Apps",
        description:
          "When you look for the best mobile apps developers in India from 3Dottt Technologies, they strive hard to develop custom mobile applications that include advanced features and security.",
      },
      {
        icon: "fa fa-mobile",
        title: "API & App Integration",
        description:
          "Hire mobile app programmers in India from 3Dottt Technologies and get a highly skilled team in creating APIs and integrating it with other mobile applications seamlessly.",
      },
      {
        icon: "fa fa-mobile",
        title: "eCommerce & mCommerce Apps",
        description:
          "Hire mobile application engineers from 3Dottt Technologies who develop eStores or mCommerce applications having intuitive UI and user-friendly UX.",
      },
      {
        icon: "fa fa-mobile",
        title: "Big Data Developers",
        description:
          "We have a team of mobile app coders with 5+ years of experience in Big Data technology such as the Hadoop framework. As of now, our developers have completed many advanced big-data projects.",
      },
      {
        icon: "fa fa-mobile",
        title: "Real-time App Developers",
        description:
          "When you look for mobile app developers for hire in India, you always get a well-experienced team with years of expertise in developing real-time applications on the latest technologies & trends.",
      },
      {
        icon: "fa fa-mobile",
        title: "AR/VR Based Apps",
        description:
          "Hire mobile app coders in India from 3Dottt Technologies to develop augmented reality and virtual reality-based mobile applications with high graphics and animations.",
      },
    ],
  };

  return (
    <>
      <div id="content" className="site-content">
        <Breadcrumbs
          title="Mobile App Development"
          paths={[
            // { name: "Home", url: "/" },
            { name: "Services", url: "/services" },
            { name: "Mobile App Development" },
          ]}
        />
        <section className="aboutus-section">
          <div className="container wow fadeInUp">
            <div className="row align-items-center">
              <div className="col-md-12 mt-3">
                <div className="section-title text-center">
                  <h2>Mobile App Development</h2>
                  <div className="dividebar"></div>
                  <p className="mt-3">
                    As a trusted mobile app development firm based in India, we
                    provide full-cycle mobility solutions from initial
                    conceptualization of the smartphone app to its final
                    deployment in the app store.
                  </p>
                </div>
              </div>
            </div>
            <div className="row align-items-center mt-5">
              <div className="col-md-12">
                <div className="service-dddd text-center">
                  <img
                    style={{ maxWidth: "300px" }}
                    src="../../wp-content/themes/wp-bootstrap-4/assets/images/q.svg"
                    alt="aws"
                  />
                  <div className="service-infobox mt-4">
                    <p>
                      More and more solutions seem to be offered in a mobile app
                      format nowadays. As a Mobile App Development Company that
                      serves clients globally, we can fashion and develop any
                      collection of ideas you’ve got in a robust and highly
                      effective mobile application.
                    </p>
                    <p>
                      The entire process delivers a highly complex and adaptable
                      mobile-specific app, which can essentially satisfy and
                      meet up with any demands you could possibly have. Thus,
                      Cloudmate group shines when it comes to alignment with
                      identification of all your needs and requirements.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <h4>The Annals of Mobile App Development</h4>
                <p>
                  At 3Dottt Technologies, our team has clearly mastered the art
                  of proper development of mobile apps at large. Not only do
                  they reflect a high sense of understanding your needs, but
                  they transform them into the underlying qualities that define
                  your mobile application. Cloudmate group are aware of all the
                  market variations when it comes to mobile app development,
                  most prominently the case of cross-platform development at
                  large. Some aspects of mobile Development, as a result,
                  involves quite a few of these pieces of information.
                </p>
                <ul style={{ paddingLeft: "20px" }}>
                  <li>Cross-Platform Development</li>
                  <li>UI/UX Design for devices</li>
                  <li>Integrating all design features</li>
                  <li>
                    Determining whether the development is for Android or iOS
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <Services
          title={mobileAppDevelopmentExpertiseData.title}
          description={mobileAppDevelopmentExpertiseData.description}
          services={mobileAppDevelopmentExpertiseData.services}
        />
        <EnterpriseSolution />
      </div>
    </>
  );
}
